import { useEffect, useState } from "react"
import moment from "moment"
import axios from 'axios'
import { Button, Modal, Box } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    minHeight: "70vh",
    maxHeight: "80vh",
    overflowY: "scroll",
    "@media(max-width: 690px)": {
        width: "90vw",
        maxHeight: "90vh",
        p: 4,
    },
};

const investigationStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    minHeight: "30vh",
    maxHeight: "40vh",
    overflow: "hidden",
    "@media(max-width: 690px)": {
        width: "40vw",
        maxHeight: "50vh",
        p: 4,
    },
};

export default function RefundModal(props) {

    const url = process.env.REACT_APP_DEPPLOYED;
    const [note, setNote] = useState("")
    const [user, setUser] = useState("")
    const [showButton, setShowButton] = useState(true)
    const [showInvestigation, setShowInvestigation] = useState(false)
    const [staffName, setStaffName] = useState("")
    const [amount, setAmount] = useState(0)

    useEffect(() => {
        let userData = localStorage.getItem("userDetails");
        let userD = JSON.parse(userData);
        setUser(userD.user.user);
        setStaffName(props.details.staffName)
    }, [props])

    const submitNote = async () => {
        setShowButton(false)
        let data = {
            refId: props.details.refId,
            note: note,
            user: user
        }
        await axios.post(`${url}/refund-reports/add-note`, data).then((resp) => {
            setNote("")
            props.open();
            setShowButton(true)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleOpen = () => setShowInvestigation(true);
    const handleClose = () => setShowInvestigation(false);

    const createInvestigation = async () => {
        let data = {
            user: user,
            refId: props.details.refId,
            staff: staffName,
            amount: amount,
            siteName: props.details.siteName,
            incidentDate: moment(props.details.transactionTime).format("YYYY-MM-DD HH:mm:ss")
        }

        console.log(data)

        await axios.post(`${url}/discrepancies/add/investigation/refunds`, data).then((resp) => {
            handleClose();
            props.open();
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <Box sx={style} >
            {props.details.type ?
                <>
                    <h2>{props.details.type.toUpperCase()} ID: {props.details.refId}</h2>
                    <div className="cigs_tab" style={{ width: "100%", border: "1px solid black", marginBottom: "2%", borderRadius: "10px" }}>
                        <table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td>Product</td>
                                    <td>Reason</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{props.details.name ?
                                        <>{props.details.name}</>
                                        : <>N/A</>}</td>
                                    <td>{props.details.reason}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                        <div style={{ width: "65%" }}>

                            <div style={{ border: "1px solid black", marginBottom: "5%", borderRadius: "10px", color: "#4d4d4d" }}>
                                <div style={{ display: "flex", justifyContent: "space-around", borderBottom: "1px solid black" }}>
                                    <h4>Transaction ID: <b>{props.details.transactionId}</b></h4>
                                    <h4>Time: <b>{moment(props.details.transactionTime).format("DD/MM/YY HH:mm:ss")}</b></h4>
                                    <h4>Total:
                                        <b>
                                            {parseFloat(props.details.totalAmount) >= 0 ?
                                                <> £{parseFloat(props.details.totalAmount).toFixed(2)}</>
                                                : <> -£{parseFloat(props.details.totalAmount.replace("-", "")).toFixed(2)}</>}
                                        </b>
                                    </h4>
                                </div>
                                <div className="cigs_tab" style={{ width: "100%", marginTop: "1%" }}>
                                    <table style={{ width: "90%" }}>
                                        <thead>
                                            <tr>
                                                <td>Name</td>
                                                <td>Price</td>
                                                <td>Qty</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.details.items.map(function (d, i) {
                                                return (
                                                    <tr>
                                                        <td>{d.name}</td>
                                                        <td>£{parseFloat(d.unitPrice).toFixed(2)}</td>
                                                        <td>{d.quantity}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ paddingTop: "0.5%", borderTop: "1px solid black", textAlign: "center" }}>
                                    {props.details.tenders.map(function (d, i) {
                                        return (
                                            <p>
                                                {d.eposName}
                                                {d.eposName == "Cash" ?
                                                    <> £{d.transactionTotal} | Change Given: £{d.changeGiven}</>
                                                    : <> ({d.name}) @ {parseFloat(d.transactionTotal) >= 0 ?
                                                        <> £{parseFloat(d.transactionTotal).toFixed(2)}</>
                                                        : <> -£{parseFloat(d.transactionTotal.replace("-", "")).toFixed(2)}</>}</>}
                                            </p>
                                        )
                                    })}
                                </div>
                                <p style={{ paddingTop: "1%", borderTop: "1px solid black", fontSize: "0.7rem", textAlign: "center" }}>{props.details.barcode}</p>
                            </div>

                            <div style={{ border: "1px solid black", borderRadius: "10px", color: "#4d4d4d", textAlign: "center" }}>
                                <h4>Notes</h4>
                                {props.details.notes.length > 0 ?
                                    <div className="cigs_tab" style={{ width: "100%", marginTop: "-2%" }}>
                                        <table style={{ width: "90%" }}>
                                            <thead>
                                                <tr>
                                                    <td>Note</td>
                                                    <td>User</td>
                                                    <td>Date/Time</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.details.notes.map(function (d, i) {
                                                    return (
                                                        <tr key={d.noteId}>
                                                            <td>{d.note}</td>
                                                            <td>{d.user}</td>
                                                            <td>{moment(d.dateTime).format("DD/MM/YYYY HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div> :
                                    <>No Notes Found</>
                                }

                                <div style={{ display: "flex", width: "90%", margin: "0 auto", justifyContent: "space-between", paddingBottom: "20px" }}>
                                    <input type="text" placeholder="Add Note....." style={{ width: "85%" }} onChange={(e) => setNote(e.target.value)} value={note} />
                                    <Button variant="contained" color="success" onClick={submitNote} disabled={!showButton}><SendIcon /></Button>
                                </div>

                            </div>
                        </div>

                        <div style={{ display: "block", width: "30%" }}>

                            <div style={{ border: "1px solid black", borderRadius: "10px", marginBottom: "10%", color: "#4d4d4d" }}>
                                <h4 style={{ textAlign: "center" }}>End Of Day: <b>{props.details.endOfDayId}</b></h4><hr />
                                <div style={{ padding: "0 5% 0 5%" }}>
                                    <p style={{ wordBreak: "break-all" }}>Submitted By: <b>{props.details.user.split("@")[0]}</b></p>
                                    <p>Created On: <b>{moment(props.details.timeCreated).format("DD/MM/YYYY HH:mm")}</b></p>
                                    <p style={{ wordBreak: "break-all" }}>Note Left: <b>{props.details.note}</b></p>
                                </div>
                            </div>

                            <div style={{ border: "1px solid black", borderRadius: "10px" }}>
                                <Button
                                    sx={{ width: "90%", margin: "5%" }}
                                    variant="contained"
                                    color="primary"
                                    disabled={props.details.investigationId}
                                    onClick={handleOpen}
                                >
                                    {props.details.investigationId?<>Investigation Created</>:<>Start Investigation</>}
                                </Button>
                                <Button
                                    sx={{ width: "90%", margin: "5%" }}
                                    variant="contained"
                                    color="error"
                                    onClick={props.close}
                                >
                                    Close
                                </Button>
                            </div>

                        </div>
                    </div>
                    <Modal open={showInvestigation} >
                        <Box sx={investigationStyle} >
                            <div style={{ color: "#4d4d4d" }}>
                                <h3 style={{ textAlign: "center" }}>Create Investigation</h3>
                                <div style={{ width: "70%", margin: "0 auto", padding: "5%" }}>
                                    <label>Employee: </label>
                                    <input type="text" style={{ marginBottom: "10%" }} placeholder="Enter Staff Name..." value={staffName} onChange={(e) => setStaffName(e.target.value)} />
                                    <br />
                                    <label>Amount: </label>
                                    <input type="number" style={{ marginBottom: "10%" }} placeholder="Enter Monetery Value..." value={amount} onChange={(e) => setAmount(e.target.value)} />
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                <Button variant="contained" color="error" onClick={handleClose}>Back</Button>
                                <Button variant="contained" color="success" onClick={createInvestigation}>Submit</Button>
                            </div>
                        </Box>
                    </Modal>
                </>
                : <></>}
        </Box>
    )
}