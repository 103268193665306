//MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
};

interface Props {
    image: string;
    handleClose: any;
}

export default function EnlargedImage({ image, handleClose }: Props) {
    return (
        <Box sx={style}>
            <img src={image} width="550" style={{ maxHeight: "80vh" }} />
            <Button
                onClick={() => handleClose(false)}
                variant="contained"
                color="error"
            >
                close
            </Button>
        </Box>
    );
}
