import { useState, useEffect, useRef } from "react";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import StorageModal from "./modals/StorageModal";
import StatusModal from "./modals/StatusModal";
import SdStorageIcon from "@mui/icons-material/SdStorage";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import NotesModal from "./modals/NotesModal";
import VehicleDetails from "../../StoreZone/storeManagement/driveoff/modals/VehicleDetails";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PhotosModal from "./modals/PhotosModal";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import QRCode from "qrcode";
import QRPrint from "./modals/QRPrint";
import CCTVModal from "./modals/CCTVModal";
import InvestigationModal from "./modals/InvetsigationModal";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";

export default function DriveOffIndividual({
    request,
    statusList,
    rel,
    fuelData,
}) {
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showStorageModal, setShowStorageModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showVehicleDetailModal, setShowVehicleDetailModal] = useState(false);
    const [showImagesModal, setShowImagesModal] = useState(false);
    const [photosArr, setPhotosArr] = useState([]);
    const [showCrimeRef, setShowCrimeRef] = useState(false);
    const [qrCode, setQrCode] = useState("");
    const [openQR, setOpenQR] = useState(false);
    const [showCCTVModal, setShowCCTVModal] = useState(false);
    const [showInvetigationModal, setShowInvestigationModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentStatus, setCurrentStatus] = useState("");
    const [currentStatusId, setCurrentStatusId] = useState(null);

    useEffect(() => {
        let statuses = statusList;
        for (let i = 0; i < statuses.length; i++) {
            if (statuses[i].statusId == request.statusId) {
                setCurrentStatus(statusList[i].name);
                setCurrentStatusId(statusList[i].statusId);
            }
        }
        const photoArr = [
            request.driverImg,
            request.itemLeftImg,
            request.signatureImg,
        ];
        setPhotosArr(photoArr);

        //Create QR code
        QRCode.toDataURL(String(request.requestId))
            .then((url) => {
                setQrCode(url);
            })
            .catch((err) => {
                console.error(err);
            });
        setFilteredData(
            fuelData.filter((fuel) => fuel.locationId == request.siteId)
        );
    }, [rel]);

    return (
        <>
            {" "}
            <tr>
                <td>{request.site}</td>
                <td>
                    {new Date(request.date).toLocaleDateString("en-GB")}-
                    {request.time}
                </td>
                <td>{request.pump}</td>
                <td className="status-cell">
                    {request.plate}
                    <Button onClick={() => setShowVehicleDetailModal(true)}>
                        <InfoIcon />
                    </Button>
                </td>
                <td>£{request.amount}</td>
                <td>{request.staffName}</td>
                <td className="status-cell">
                    {currentStatus}
                    <Button onClick={() => setShowStatusModal(true)}>
                        <EditIcon />
                    </Button>
                </td>
                <td>
                    {showCrimeRef ? (
                        <span onClick={() => setShowCrimeRef(!showCrimeRef)}>
                            {request.crimeReference}
                        </span>
                    ) : request.crimeReference ? (
                        <RemoveRedEyeIcon
                            color="primary"
                            onClick={() => setShowCrimeRef(!showCrimeRef)}
                        />
                    ) : (
                        <VisibilityOffIcon />
                    )}
                </td>
                <td>
                    {" "}
                    {!request.storageLocation ? (
                        <SdStorageIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setShowStorageModal(true);
                            }}
                        />
                    ) : (
                        <Tooltip title="Click to copy">
                            <SdStorageIcon
                                style={{ cursor: "pointer" }}
                                color="success"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        request.storageLocation
                                    );
                                }}
                            />
                        </Tooltip>
                    )}
                </td>
                <td>
                    {(request.images && request.images.length > 0) ||
                    request.signatureImg ? (
                        <CameraAltIcon
                            style={{ cursor: "pointer" }}
                            color="primary"
                            onClick={(e) => setShowImagesModal(true)}
                        />
                    ) : (
                        <CameraAltIcon />
                    )}
                </td>
                <td>
                    {" "}
                    {request.notes.length > 0 ? (
                        <SpeakerNotesIcon
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowNotesModal(true)}
                        />
                    ) : (
                        <SpeakerNotesOffIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowNotesModal(true)}
                        />
                    )}
                </td>
                <td>
                    {filteredData.length > 0 && request.hasCCTVLink == false ? (
                        <Tooltip title="Click to Match Fuel">
                            <LocalGasStationIcon
                                onClick={() => setShowCCTVModal(true)}
                                color="primary"
                                style={{ cursor: "pointer", fontSize: "30px" }}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title={
                                request.hasCCTVLink
                                    ? "Fuel Has Been Matched"
                                    : "No Fuel Data Found"
                            }
                        >
                            <LocalGasStationIcon
                                color={
                                    request.hasCCTVLink ? "success" : "error"
                                }
                                style={{ cursor: "pointer", fontSize: "30px" }}
                            />
                        </Tooltip>
                    )}
                </td>
                <td>
                    <Tooltip title="Investigation">
                        <PersonSearchIcon
                            onClick={() => setShowInvestigationModal(true)}
                            style={{ cursor: "pointer", fontSize: "30px" }}
                            color={request.hasInvestigation ? "primary" : ""}
                        />
                    </Tooltip>
                </td>{" "}
                <td>
                    <img
                        onClick={() => setOpenQR(true)}
                        src={qrCode}
                        style={{ cursor: "pointer" }}
                        width="60"
                    />
                </td>
                <td>
                    {moment(request.submissionDate).format("DD/MM/YY - HH:mm")}
                </td>{" "}
            </tr>
            <Modal open={openQR}>
                <QRPrint
                    qrCode={qrCode}
                    setOpenQR={setOpenQR}
                    request={request}
                />
            </Modal>
            <Modal
                aria-labelledby="Status Change Modal"
                aria-describedby="Status Change Modal"
                open={showStatusModal}
            >
                <StatusModal
                    handleCloseStatus={setShowStatusModal}
                    currentStatus={currentStatus}
                    reqId={request.requestId}
                    rel={rel}
                    statusList={statusList}
                    isDev={true}
                    currentStatusId={currentStatusId}
                    siteName={request.site}
                    siteId={request.siteId}
                />
            </Modal>
            <Modal
                aria-labelledby="Storage Modal"
                aria-describedby="Storage Modal"
                open={showStorageModal}
            >
                <StorageModal
                    handleCloseStorage={setShowStorageModal}
                    cStatus={request.statusId}
                    id={request.requestId}
                    rel={rel}
                    request={request}
                />
            </Modal>
            <Modal
                aria-labelledby="Storage Modal"
                aria-describedby="Storage Modal"
                open={showNotesModal}
            >
                <NotesModal
                    handleCloseNotes={setShowNotesModal}
                    allNotes={request.notes}
                    id={request.requestId}
                    rel={rel}
                    isDev={true}
                    requestType={request.requestType}
                />
            </Modal>
            <Modal
                aria-labelledby="Vehicles Details Modal"
                aria-describedby="Vehicles Details Modal"
                open={showVehicleDetailModal}
            >
                <VehicleDetails
                    setShowVehicleDetailModal={setShowVehicleDetailModal}
                    registration={request.plate}
                    id={request.requestId}
                />
            </Modal>
            <Modal open={showImagesModal}>
                <PhotosModal
                    setShowImagesModal={setShowImagesModal}
                    images={request.signatureImg ? photosArr : request.images}
                />
            </Modal>
            <Modal open={showCCTVModal}>
                <CCTVModal
                    handleClose={() => setShowCCTVModal(false)}
                    request={request}
                    fuelData={fuelData}
                    rel={rel}
                />
            </Modal>
            <Modal
                open={showInvetigationModal}
                onClose={() => setShowInvestigationModal(false)}
            >
                <InvestigationModal
                    request={request}
                    rel={rel}
                    closeModal={() => setShowInvestigationModal(false)}
                />
            </Modal>
        </>
    );
}
