import { useState, useRef } from "react";
import axios from "axios";
//MUI & Styling
import { Box, Alert } from "@mui/material";
import Button from "@mui/material/Button";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "./cctv-admin-home.scss";

interface Props {
    requestId: number;
    handleClose: any;
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function UploadPhotoModal({ requestId, handleClose }: Props) {
    //TODO: CHANGE URL ACCORDINGLY
    const url = "";
    const [type, setType] = useState<string>("");
    const [imageUpload, setImageUpload] = useState<string | null>(null);
    // Ref for the file input element
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    //Alert
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");
    const [disable, setDisable] = useState<boolean>(false);

    const handleAlert = (message: string, severity: any, duration = 4000) => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setMessage("");
            setSeverity("");
            setAlert(false);
            setDisable(false);
        }, duration);
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0]; // Get the selected file
        if (file) {
            // Create an object URL for the selected file
            const objectURL: any = URL.createObjectURL(file);
            setImageUpload(objectURL); // Set the image URL in state
        }
    };

    //Validation for the photo upload form - will submit only on successful validation pass
    const handleValidation = () => {
        setDisable(true);
        if (type === "") {
            handleAlert("Please select Internal or External", "warning");
        } else if (imageUpload === null) {
            handleAlert("Please upload an image", "warning");
        } else {
            handlePhotoUpload();
        }
    };

    //Final upload photo function
    const handlePhotoUpload = async () => {
        //TODO: PLUG IN ENPOINT ONCE CREATED
        let reqBody = {
            requestId: requestId,
            image: imageUpload,
            type: type,
        };

        try {
            let response = await axios.post(`${url}/ENPOINT`, reqBody);
            if (response && response.status === 200) {
                handleAlert("Image successfully uploaded", "success");
            } else {
                handleAlert("Something went wrong - Please try again", "error");
            }
        } catch (err) {
            console.error("Error uploading image:", err);
            handleAlert(
                "An error occurred during upload. Please try again later.",
                "error"
            );
        } finally {
            setDisable(false);
        }
    };

    const handleDeletePhoto = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setImageUpload(null);
    };

    return (
        <Box sx={style} className="upload-photo-modal">
            <h1>Upload Photo</h1>
            <section className="upload-section">
                <label>
                    Is the photo internal (Head Office only) or external (all
                    sites)?
                </label>
                <select onChange={(e) => setType(e.target.value)} value={type}>
                    <option>Please select an option</option>
                    <option>Internal</option>
                    <option>External</option>
                </select>
                <div className="img-container">
                    {imageUpload ? (
                        <>
                            <DeleteForeverIcon
                                color="error"
                                className="delete-icon"
                                onClick={handleDeletePhoto}
                            />
                            <img src={imageUpload} />
                        </>
                    ) : (
                        <AddAPhotoIcon fontSize="large" />
                    )}
                </div>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="photo-upload-input"
                    ref={fileInputRef}
                />
            </section>
            <section className="alert-section">
                {alert ? <Alert severity={severity}>{message}</Alert> : null}
            </section>
            <section className="btn-section">
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleClose(false)}
                >
                    close
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleValidation}
                    disabled={disable}
                >
                    submit
                </Button>
            </section>
        </Box>
    );
}
