//?==============================================================
//? THIS ALERT CAN BE USED AS A GLOBAL ALERT
//? CHANGE THE CONTENTS AND IMPORT TO REQUIRED COMPONENT WHEN REQUIRED
//? PASS THIS COMPONENT INTO A MODAL AND PASS IT CLOSE FUNCTION
//?==============================================================

//MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
//Styling
import "./alerts.scss";
import GIFTS from "../../images/GIFTS.png";
interface Props {
    handleClose: any;
}

export default function GlobalAlert({ handleClose }: Props) {
    return (
        <Box className="global-alert-modal">
            <header>
                {" "}
                <h1>Christmas Support</h1>{" "}
                <img src={GIFTS} className="xmas-gifts" />
            </header>

            <section className="alert-content">
                <p className="more-info">
                    Support hours during the festive period can be found using
                    the following link:{" "}
                    <a href="https://nitrosolutions.co.uk/festive" target="_blank">
                        Nitro Solutions
                    </a>
                </p>
            </section>
            <Button
                onClick={() => handleClose(false)}
                variant="contained"
                color="error"
            >
                Close
            </Button>
        </Box>
    );
}
