import { useState, useEffect } from "react";
import DriveOffIndividual from "./DriveOffIndividual";
import axios from "axios";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../animations/Loader";

export default function DriveoffRequests({ statusList }) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [displayedRequests, setDisplayedRequests] = useState([]);
    const [fixedRequests, setFixedRequests] = useState([]);
    const [fuelData, setFuelData] = useState([]);
    const [reload, setReload] = useState(false);
    const [sorted, setSorted] = useState(false);
    const [disable, setDisable] = useState(false);
    const [plate, setPlate] = useState("");
    const [loader, setLoader] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(0);

    useEffect(() => {
        setLoader(true);
        axios
            .get(
                `${url}/cctv-system/submissions/details/allActive?driveoff=true`
            )
            .then((res) => {
                // sort CCTV requests by submission date
                let _requests = res.data.cctv;
                _requests = _requests.sort(
                    (a, b) =>
                        new Date(b.submissionDate) - new Date(a.submissionDate)
                );

                setFixedRequests(_requests);
                setDisplayedRequests(_requests);
                setFuelData(res.data.fuelData);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    const search = () => {
        if (plate == "") {
            rel();
        } else {
            axios
                .get(`${url}/cctv-system/submission/search?search=${plate}`)
                .then((res) => {
                    setDisable(true);
                    setTimeout(() => {
                        setDisable(false);
                    });
                    setDisplayedRequests(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    //Will filter displayed requests status filters
    const handleFilter = (value, field) => {
        let filterData = {
            statusId: field === "status" ? value : selectedStatus,
        };

        //Remove any key/value pairs from the filtered data where the value is 0 (default)
        const removeZeros = (item) =>
            Object.keys(item)
                .filter((key) => item[key] !== 0 && item[key] !== "")
                .reduce((newObj, key) => {
                    newObj[key] = item[key];
                    return newObj;
                }, {});
        const _result = removeZeros(filterData);

        //Create a new filter array of the key value pairs that have to be filtered
        let filteredArray = [];
        for (const [key, value] of Object.entries(_result)) {
            filteredArray.push({ key: key, value: value });
        }

        //Loop through filter array and set new array
        let finalFiltered = fixedRequests;
        for (let index = 0; index < filteredArray.length; index++) {
            finalFiltered = finalFiltered.filter(
                (order) =>
                    order[filteredArray[index].key] ==
                    filteredArray[index].value
            );
        }

        //Set Current filter selects
        if (field === "status") {
            setSelectedStatus(value);
        }
        //Set new displayed requests
        setDisplayedRequests(finalFiltered);
    };

    return (
        <main className="cctv__requests">
            <h1 className="cctv-header">Drive Off - Dev Area</h1>

            {!loader ? (
                <>
                    <div className="drive-off-search">
                        <select
                            className="status-filter"
                            onChange={(e) => {
                                handleFilter(
                                    parseInt(e.target.value),
                                    "status"
                                );
                            }}
                            value={selectedStatus}
                        >
                            <option value={0}>Filter By Status</option>
                            {statusList && statusList.length > 0
                                ? statusList.map((status, i) => {
                                      return (
                                          <option
                                              key={i}
                                              value={status.statusId}
                                          >
                                              {status.name}
                                          </option>
                                      );
                                  })
                                : null}
                        </select>
                        <input
                            type="text"
                            focus
                            onChange={(e) => setPlate(e.target.value)}
                            placeholder="Search Plate or Ref"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    search();
                                }
                            }}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={disable}
                            onClick={search}
                            disableElevation
                        >
                            <SearchIcon />
                        </Button>{" "}
                        <p className="notif_sub_level">
                            Pending: {fuelData.length}
                        </p>
                    </div>
                    {displayedRequests?.length > 0 ? (
                        <section className="cctv-dev-table-container">
                            <table className="cctv__table">
                                <thead>
                                    <tr>
                                        <th className="filter_heading">Site</th>
                                        <th className="filter_heading">
                                            Incident Date
                                        </th>
                                        <th>Pump</th>
                                        <th>Registration</th>
                                        <th>Amount</th>
                                        <th>Staff</th>
                                        <th className="filter_heading">
                                            Status
                                        </th>
                                        <th>Crime Ref</th>
                                        <th>Storage</th>
                                        <th>Photos</th>
                                        <th>Notes</th>
                                        <th>CCTV Driveoff Requests</th>
                                        <th>Investigation</th>
                                        <th>QR</th>
                                        <th>Submission Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayedRequests &&
                                    displayedRequests.length > 0 ? (
                                        displayedRequests.map((request, i) => {
                                            let currentStatus = "";
                                            for (
                                                let i = 0;
                                                i < statusList.length;
                                                i++
                                            ) {
                                                if (
                                                    statusList[i].statusId ===
                                                    request.statusId
                                                ) {
                                                    currentStatus =
                                                        statusList[i].name;
                                                }
                                            }
                                            return (
                                                <DriveOffIndividual
                                                    request={request}
                                                    currentStatus={
                                                        currentStatus
                                                    }
                                                    statusList={statusList}
                                                    rel={rel}
                                                    key={i}
                                                    fuelData={fuelData}
                                                />
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <th colSpan={14}>
                                                No open requests
                                            </th>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </section>
                    ) : (
                        <h2>No Drive Off Requests</h2>
                    )}{" "}
                </>
            ) : (
                <Loader />
            )}
        </main>
    );
}
