import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { numberValidationFloat } from "../../utils/Validation";
//MUI
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import { Modal } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import { Tooltip } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
//Components
import CCTVAdminStolenItems from "./CCTVAdminStolenItems";
import EnlargedImage from "./EnlargedImage";
import UploadPhotoModal from "./UploadPhotoModal";
//Styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    height: "80vh",
};
//Inferfaces
interface Props {
    setOpenInfo: any;
    request: any;
    user: any;
    reloadRequestData: (load: boolean) => void;
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

//MUI TABS SECTION START------------------
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
//MUI TABS SECTION END------------------

export default function CCTVAdminInfoModal({
    request,
    setOpenInfo,
    user,
    reloadRequestData,
}: Props) {
    //Tabs
    const [value, setValue] = useState<number>(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    //Notes & Modal Tab Sections
    const [storeNotes, setStoreNotes] = useState<any[]>([]);
    const [adminNotes, setAdminNotes] = useState<any[]>([]);
    const [newAdminNote, setNewAdminNote] = useState<string>("");
    const [newCrimeRef, setNewCrimeRef] = useState<string>("");
    const [nmopImages, setNmopImages] = useState<any>({});
    const [investigationName, setInvestigationName] = useState<string>("");
    const [investigationAmount, setInvestigationAmount] = useState<string>(
        request.amount ? request.amount : ""
    );
    const [cctvImages, setCctvImages] = useState<any[]>([]);
    const [enlargedImage, setEnlargedImage] = useState<string>("");
    //Modals
    const [openStolenItems, setOpenStolenItems] = useState<boolean>(false);
    const [enlargedImageModal, setEnlargedModal] = useState<boolean>(false);
    const [photoUploadModal, setPhotoUploadModal] = useState(false);
    //Alerts & Reload
    const [alert, setAlert] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");
    const [reloadNotes, setReloadNotes] = useState<boolean>(false);

    useEffect(() => {
        //Bring in store/dev notes
        axios
            .get(
                `${process.env.REACT_APP_ANPR_URL}/cctv-system/submissions/notes/${request.requestId}`
            )
            .then((res) => {
                setStoreNotes(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        //Bring in Admin notes
        axios
            .get(
                `${process.env.REACT_APP_ANPR_URL}/cctv-system/admin/notes/${request.requestId}`
            )
            .then((res) => {
                setAdminNotes(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        //If request is a NMOP then bring in images
        if (request.requestType === 3) {
            let reqbody = [
                { type: "signature", key: request.signature },
                { type: "driver", key: request.driver },
                { type: "item", key: request.itemLeft },
            ];
            axios
                .post(
                    `${process.env.REACT_APP_ANPR_URL}/cctv-system/submissions/photos`,
                    reqbody
                )
                .then(async (res) => {
                    let _res: any = {};
                    await res.data.filter((item: any, i: number) => {
                        if (item.type === "driver") {
                            _res.driver = item.src;
                        } else if (item.type === "signature") {
                            _res.signature = item.src;
                        } else if (item.type === "item") {
                            _res.item = item.src;
                        }
                    });
                    setNmopImages(_res);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        //If request is a CCTV then bring in images

        if (request.requestType === 2 && request.images !== null) {
            let _formattedImages = JSON.parse(request.images);
            let _allImages = [];
            for (let i = 0; i < _formattedImages.length; i++) {
                let _singleImage = {
                    type: "photo",
                    key: _formattedImages[i],
                };
                _allImages.push(_singleImage);
            }

            axios
                .post(
                    `${process.env.REACT_APP_ANPR_URL}/cctv-system/submissions/photos`,
                    _allImages
                )
                .then(async (res) => {
                    setCctvImages(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        //Refactor Images string array to correct array format
    }, [reloadNotes]);

    const handleAlert = (
        message: string,
        severity: any,
        toRel: boolean,
        duration = 4000
    ): void => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
            if (toRel) {
                reloadRequestData(false);
                setReloadNotes(!reloadNotes);
            }
        }, duration);
    };

    const handleAdminNoteValidation = (): void => {
        setDisable(true);
        if (newAdminNote === "") {
            handleAlert("You have not entered a note", "warning", false);
        } else {
            handleAdminNoteSubmit();
        }
    };

    const handleAdminNoteSubmit = (): void => {
        let reqBody = {
            note: newAdminNote,
            user: user.user.user,
            requestId: request.requestId,
        };

        axios
            .post(
                `${process.env.REACT_APP_ANPR_URL}/cctv-system/admin/notes`,
                reqBody
            )
            .then((res) => {
                handleAlert("Note successfully submitted", "success", true);
                setNewAdminNote("");
            })
            .catch((err) => {
                console.log(err);
                handleAlert(
                    "Note did not submit - Please try again",
                    "error",
                    false
                );
            });
    };

    const handleCrimeRefValidation = (): void => {
        setDisable(true);
        if (newCrimeRef === "") {
            handleAlert(
                "You have not entered a new crime reference",
                "warning",
                false
            );
        } else {
            handleCrimeRefSubmit();
        }
    };
    const handleCrimeRefSubmit = (): void => {
        let reqBody = {
            user: user.user.user,
            crimeReference: newCrimeRef,
            requestId: request.requestId,
        };

        axios
            .post(
                `${process.env.REACT_APP_ANPR_URL}/cctv-system/submissions/crime-ref`,
                reqBody
            )
            .then((res) => {
                handleAlert(
                    "Crime Reference successfully submitted",
                    "success",
                    true
                );
                setNewCrimeRef("");
            })
            .catch((err) => {
                console.log(err);
                handleAlert(
                    "Crime reference did not submit - please try again",
                    "error",
                    false
                );
            });
    };

    const handleInvestigationValidation = () => {
        setDisable(true);
        if (investigationName === "") {
            handleAlert("You have not entered a staff name", "warning", false);
        } else if (investigationAmount === "" || investigationAmount === "0") {
            handleAlert(
                "You have not entered an amount to be charged",
                "warning",
                false
            );
        } else {
            handleInvestigationSubmit();
        }
    };

    const handleInvestigationSubmit = () => {
        let reqBody = {
            user: user.user.user,
            requestId: request.requestId,
            amount: investigationAmount,
            siteName: request.site,
            incidentDate: moment(request.date).format("YYYY-MM-DD"),
            staff: investigationName,
        };

        axios
            .post(
                `${process.env.REACT_APP_ANPR_URL}/discrepancies/add/investigation/cctv`,
                reqBody
            )
            .then((res) => {
                handleAlert(
                    "Staff Investigation successfully submitted",
                    "success",
                    true
                );
                setInvestigationName("");
            })
            .catch((err) => {
                console.log(err);
                handleAlert(
                    "Staff Investigation did not submit - please try again",
                    "error",
                    false
                );
            });
    };

    const handleEnlargeImage = (image: string) => {
        setEnlargedImage(image);
        setEnlargedModal(true);
    };

    return (
        <Box className="cctv-admin-info-modal" sx={style}>
            <p className="request-identifier">{request.requestId}</p>
            <h1>
                <span>{request.typeName}</span>
                <span className="info-site">{request.site}</span>
                <span className="info-date">
                    {moment(request.date).format("DD/MM/YY")}-{request.time}
                </span>
                <span className="info-staff">{request.staffName}</span>
                <span className="info-status"> {request.statusName}</span>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenInfo(false)}
                    className="close-btn"
                    disabled={disable}
                >
                    <CloseIcon />
                </Button>
            </h1>
            <section className="top-section">
                {request.requestType === 2 ? (
                    <>
                        <div className="info-section">
                            <h2>Description of Incident</h2>
                            <p>{request.description}</p>
                            <h2>Items Stolen</h2>
                            <p>
                                {request.items
                                    ? request.items
                                    : "No details provided"}
                            </p>
                        </div>
                        <div className="info-section">
                            <h2>People Involved</h2>

                            <p>
                                {request.person
                                    ? request.person
                                    : "No details provided"}
                            </p>
                            <div className="info-section-images">
                                <h2>
                                    Images{" "}
                                    {/*!!!! THE BELOW BUTTON SHOULD BE UNCOMMENTED WHEN THE PHOTO UPLOAD MODAL IS COMPLETE  */}
                                    {/* <Tooltip title="Upload Photo">
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            onClick={() =>
                                                setPhotoUploadModal(true)
                                            }
                                            sx={{ marginBottom: "5px" }}
                                        >
                                            <AddAPhotoIcon fontSize="small" />
                                        </Button>
                                    </Tooltip> */}
                                </h2>
                                {cctvImages.length > 0 ? (
                                    cctvImages.map((image: any, i: number) => {
                                        return (
                                            <img
                                                src={image.src}
                                                alt="CCTV Image"
                                                key={i}
                                                onClick={() =>
                                                    handleEnlargeImage(
                                                        image.src
                                                    )
                                                }
                                            />
                                        );
                                    })
                                ) : (
                                    <p>No Images</p>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="info-section">
                            <h2>Make</h2>
                            <p>
                                {request.make ? request.make : "Not Available"}
                            </p>
                            <h2>MOT Status</h2>
                            <p>
                                {request.motStatus
                                    ? request.motStatus
                                    : "Not Available"}
                            </p>
                            <h2>Tax Status</h2>
                            <p>
                                {request.taxStatus
                                    ? request.taxStatus
                                    : "Not Available"}
                            </p>
                        </div>
                        <div className="info-section">
                            <h2>Registration (Year)</h2>
                            <p>
                                {request.plate
                                    ? request.plate
                                    : "Not Available"}{" "}
                                {request.yearOfManufacture
                                    ? `(${request.yearOfManufacture})`
                                    : "(No year Available)"}
                            </p>
                            <h2>Fuel Type</h2>
                            <p>
                                {request.fuelType
                                    ? request.fuelType
                                    : "Not Available"}
                            </p>
                            <h2>Pump Number</h2>
                            <p>
                                {request.pump ? request.pump : "Not Available"}
                            </p>
                        </div>
                        <div className="info-section">
                            <h2>Amount (£)</h2>
                            <p>
                                {request.amount
                                    ? `£${request.amount}`
                                    : "Not Available"}
                            </p>
                            <h2>Litres</h2>
                            <p>
                                {request.litres
                                    ? request.litres
                                    : "Not Available"}
                            </p>
                            <h2>Police Officer Dealing with case</h2>
                            <p>
                                {request.officerName
                                    ? request.officerName
                                    : "Not Available"}
                            </p>
                        </div>
                    </>
                )}
            </section>
            <section className="bottom-section">
                <Box sx={{ width: "100%" }} className="tab-box">
                    <Box
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                        className="tab-menu-section"
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="Notes Tabs"
                        >
                            <Tab
                                label="Store / Dev Notes"
                                {...a11yProps(0)}
                                disabled={disable}
                            />
                            <Tab
                                label="Admin Notes"
                                {...a11yProps(1)}
                                disabled={disable}
                            />
                            <Tab
                                label="Create Admin Note"
                                {...a11yProps(2)}
                                disabled={disable}
                            />
                            <Tab
                                label="Stolen Items"
                                {...a11yProps(3)}
                                disabled={disable}
                            />
                            <Tab
                                label="Crime Reference"
                                {...a11yProps(4)}
                                disabled={disable}
                            />
                            <Tab
                                label="NMOP Details"
                                {...a11yProps(5)}
                                disabled={disable}
                            />
                            <Tab
                                label="Investigation"
                                {...a11yProps(6)}
                                disabled={disable}
                            />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        {storeNotes.length > 0 ? (
                            <div className="note-table-container">
                                <table className="note-table">
                                    <thead>
                                        <tr>
                                            <th>Submitted By</th>
                                            <th>Note</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {storeNotes.map(
                                            (note: any, i: number) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{note.user}</td>
                                                        <td>{note.note}</td>
                                                        <td>
                                                            {moment(
                                                                note.noteDate
                                                            ).format(
                                                                "DD/MM/YY HH:mm"
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <h2>There are no store/dev notes yet</h2>
                        )}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {adminNotes.length > 0 ? (
                            <div className="note-table-container">
                                <table className="note-table">
                                    <thead>
                                        <tr>
                                            <th>Submitted By</th>
                                            <th>Note</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adminNotes.map(
                                            (note: any, i: number) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{note.user}</td>
                                                        <td>{note.note}</td>
                                                        <td>
                                                            {moment(
                                                                note.dateCreated
                                                            ).format(
                                                                "DD/MM/YY HH:mm"
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <h2>There are no admin notes yet</h2>
                        )}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <div className="create-admin-note">
                            {" "}
                            <textarea
                                placeholder="Enter note..."
                                onChange={(e) =>
                                    setNewAdminNote(e.target.value)
                                }
                                value={newAdminNote}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleAdminNoteValidation();
                                    }
                                }}
                            />
                            <div className="alert-section">
                                {alert ? (
                                    <Alert severity={severity}>{message}</Alert>
                                ) : null}
                            </div>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleAdminNoteValidation}
                                disabled={disable}
                            >
                                Submit
                            </Button>
                        </div>
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={3}>
                        {" "}
                        {request.requestType === 2 ? (
                            <h2>Stolen Items Section - Coming Soon!</h2>
                        ) : (
                            <h2>Not available in Drive off/NMOP requests</h2>
                        )}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        <div className="crime-ref-section">
                            {request.crimeReference ? (
                                <h2>
                                    This request has a Crime Reference of{" "}
                                    <span>{request.crimeReference}</span>
                                </h2>
                            ) : (
                                <h2>
                                    This request does not have a Crime Reference
                                </h2>
                            )}
                            <p>
                                To create a new one or replace an existing crime
                                reference, enter the new one below and submit.
                            </p>
                            <input
                                type="text "
                                placeholder="Enter crime reference..."
                                onChange={(e) => setNewCrimeRef(e.target.value)}
                                value={newCrimeRef}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleCrimeRefValidation();
                                    }
                                }}
                            />
                            <div className="alert-section">
                                {alert ? (
                                    <Alert severity={severity}>{message}</Alert>
                                ) : null}
                            </div>
                            <Button
                                variant="contained"
                                color="success"
                                disabled={disable}
                                onClick={handleCrimeRefValidation}
                            >
                                Submit
                            </Button>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={5}>
                        <div className="nmop-details">
                            {request.requestType === 3 ||
                            request.requestType === 4 ? (
                                <>
                                    <div className="info-section">
                                        <h2>Customer Name</h2>
                                        <p>
                                            {request.name
                                                ? request.name
                                                : "Not Available"}
                                        </p>
                                        <h2>Customer Email</h2>
                                        <p>
                                            {request.email
                                                ? request.email
                                                : "Not Available"}
                                        </p>
                                        <h2>Customer Phone</h2>
                                        <p>
                                            {request.phone
                                                ? request.phone
                                                : "Not Available"}
                                        </p>{" "}
                                    </div>
                                    <div className="info-section">
                                        <h2>Address Line 1</h2>
                                        <p>
                                            {request.addressLine1
                                                ? request.addressLine1
                                                : "Not Available"}
                                        </p>
                                        <h2>Address Line 2</h2>
                                        <p>
                                            {request.addressLine2
                                                ? request.addressLine2
                                                : "Not Available"}
                                        </p>
                                        <h2>Signature Image</h2>
                                        <p>
                                            {nmopImages.signature ? (
                                                <img
                                                    src={nmopImages.signature}
                                                    alt="Customer signature"
                                                    onClick={() =>
                                                        handleEnlargeImage(
                                                            nmopImages.signature
                                                        )
                                                    }
                                                />
                                            ) : (
                                                "Not Available"
                                            )}
                                        </p>
                                    </div>

                                    <div className="info-section">
                                        {" "}
                                        <h2>Driver Image</h2>
                                        <p>
                                            {nmopImages.driver ? (
                                                <img
                                                    src={nmopImages.driver}
                                                    alt="Image of Customer"
                                                    onClick={() =>
                                                        handleEnlargeImage(
                                                            nmopImages.driver
                                                        )
                                                    }
                                                />
                                            ) : (
                                                "Not Available"
                                            )}
                                        </p>{" "}
                                        <h2>Item Image</h2>
                                        <p>
                                            {nmopImages.item ? (
                                                <img
                                                    src={nmopImages.item}
                                                    alt="Image of Item Left"
                                                    onClick={() =>
                                                        handleEnlargeImage(
                                                            nmopImages.item
                                                        )
                                                    }
                                                />
                                            ) : (
                                                "Not Available"
                                            )}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <h2 className="no-nmop-details">
                                    No Details available for this type of
                                    request
                                </h2>
                            )}
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={6}>
                        <div className="investigation-tab">
                            {!request.hasInvestigation ? (
                                <div className="investigation-area">
                                    <p>
                                        Please enter the name of the staff
                                        member that you are starting an
                                        investigation on.
                                    </p>
                                    <input
                                        type="text"
                                        placeholder="Enter staff member name..."
                                        onChange={(e) =>
                                            setInvestigationName(e.target.value)
                                        }
                                        value={investigationName}
                                    />
                                    {request.requestType === 2 ? (
                                        <div className="investigation-amount">
                                            <CurrencyPoundIcon />
                                            <input
                                                type="number"
                                                placeholder="Enter value to be charged..."
                                                onChange={(e) =>
                                                    setInvestigationAmount(
                                                        e.target.value
                                                    )
                                                }
                                                value={investigationAmount}
                                                onKeyDown={(e) =>
                                                    numberValidationFloat(e)
                                                }
                                            />
                                        </div>
                                    ) : null}
                                    <div className="alert-section">
                                        {alert ? (
                                            <Alert severity={severity}>
                                                {message}
                                            </Alert>
                                        ) : null}
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        disabled={disable}
                                        onClick={handleInvestigationValidation}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            ) : (
                                <h2>
                                    An Investigation has already been submitted
                                    for this request.
                                </h2>
                            )}
                        </div>
                    </CustomTabPanel>
                </Box>
            </section>
            {/* MODALS */}
            <Modal open={openStolenItems}>
                <CCTVAdminStolenItems reqId={request.requestId} />
            </Modal>
            <Modal open={enlargedImageModal}>
                <EnlargedImage
                    image={enlargedImage}
                    handleClose={setEnlargedModal}
                />
            </Modal>
            <Modal open={photoUploadModal}>
                <UploadPhotoModal
                    requestId={request.requestId}
                    handleClose={setPhotoUploadModal}
                />
            </Modal>
        </Box>
    );
}
