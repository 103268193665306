import { useState, useEffect } from "react";
import axios from "axios";
import "../styles/fuelSales.scss";
import "./fuel-reporting.scss";
import SearchIcon from "@mui/icons-material/Search";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import PaymentsIcon from "@mui/icons-material/Payments";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import RestorePageIcon from "@mui/icons-material/RestorePage";
//calendar import
import DatePicker from "react-date-picker"; //'react-date-picker/dist/entry.nostyle'#
import moment from "moment";
import Loader from "../../animations/Loader";
//modal imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IndvNotes from "../IndvNotes";
import UnMatchModal from "../UnMatchModal";
import IgnoreModal from "../IgnoreModal";

//modal styles
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    borderRadius: "1rem",
    backgroundColor: "#fff",
    boxShadow: 24,
    p: 4,
    color: "#000",
    height: "45vh",
    overflowY: "scroll",
};
const discrepancyStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    borderRadius: "1rem",
    backgroundColor: "#fff",
    boxShadow: 24,
    p: 4,
    color: "#000",
    height: "60vh",
    overflowY: "scroll",
};
const noteStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    borderRadius: "1rem",
    backgroundColor: "#fff",
    boxShadow: 24,
    p: 4,
    color: "#000",
    height: "30vh",
};

const finalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    borderRadius: "1rem",
    backgroundColor: "#fff",
    boxShadow: 24,
    p: 4,
    color: "#000",
    minheight: "15vh",
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: "#2c53a0",
    border: "2px solid #2c53a0",
    backgroundColor: "#fff",
    "&:hover": {
        backgroundColor: "#2c53a0",
        color: "#fff",
    },
}));

export default function FuelReporting() {
    const [userEmail, setUserEmail] = useState("");
    const [site, setSite] = useState("");
    const [date, setDate] = useState(new Date());
    const [salesInfo, setSalesInfo] = useState(false);
    const [loader, setLoader] = useState(false);
    const [infoExpand, setInfoExpand] = useState(true);
    const [alert, setAlert] = useState(false);
    const [prismTotal, setPrismTotal] = useState(0);
    const [eposTotal, setEposTotal] = useState(0);
    const [prismTxt, setPrismTxt] = useState([]);
    const [eposTxt, setEposTxt] = useState([]);
    const [discrepancy, setDiscrepancy] = useState(0);
    const [matchedArray, setMatchedArray] = useState([]);
    const [prismSorted, setPrismSorted] = useState([]);
    const [eposSorted, setEposSorted] = useState([]);
    const [dates, setDates] = useState([]);
    const [open, setOpen] = useState(false);
    const [unmatched, setUnmatched] = useState([]);
    const [matchedReason, setMatchedReason] = useState("");
    const [reasonAlert, setReasonAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [totalMatchedArray, setTotalMatchedArray] = useState(0);
    const [finalisedButton, setFinalisedButton] = useState(true);
    const [tinyLoader, setTinyLoader] = useState(false);
    const [user, setUser] = useState("");
    const [tenderModal, setTenderModal] = useState(false);
    const [headerData, setHeaderData] = useState({});
    const [tenderAlert, setTenderAlert] = useState(false);
    const [openDiscrepancies, setOpenDiscrepancies] = useState(false);
    const [openUnMatch, setOpenUnMatch] = useState(false);
    const [notes, setNotes] = useState();
    const [finalised, setFinalised] = useState([]);
    const [openIgnore, setOpenIgnore] = useState(false);
    const [openFinalise, setOpenFinalise] = useState(false);
    //states for notes
    const [txtID, setTxtID] = useState();
    const [txtFrom, setTxtFrom] = useState("");
    const [txtIndex, setTxtIndex] = useState("");
    const [ignored, setIgnored] = useState(false);
    const [reload, setReload] = useState(false);
    const [note, setNote] = useState("");
    const [openAllNote, setOpenAllNote] = useState(false);
    const [openNote, setOpenNote] = useState(false);

    useEffect(() => {
        setMatchedArray([]);
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setUserEmail(user.user.user);

        let role = localStorage.getItem("role");
        setUser(role);
        setShowMessage(false);
        if (showMessage === true) {
            setShowMessage(false);
        }
    }, [reload]);

    //RELOAD FUNCTION
    const rel = () => {
        setReload(!reload);
    };

    //===HANDLE OPEN/CLOSE FUNCTION START===
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleOpenDiscrepancies = () => {
        setOpenDiscrepancies(true);
    };
    const handleCloseCDiscrepancies = () => setOpenDiscrepancies(false);

    const handleOpenNote = () => {
        setOpenNote(true);
        document.getElementById("context__menu").style.display = "none";
    };

    const handleCloseNote = () => {
        setOpenNote(false);
        setTxtID("");
        setTxtFrom("");
        setTxtIndex("");
        setNotes([]);
    };

    const handleOpenUnMatch = () => {
        if (finalised.includes(moment(date).format("YYYY-MM-DD"))) {
            console.log("No able to unmatch a finalised day");
        } else {
            setOpenUnMatch(true);
            document.getElementById("context__menu").style.display = "none";
        }
    };
    const handleCloseUnMatch = () => {
        setOpenUnMatch(false);
        setTxtID("");
        setTxtFrom("");
        setTxtIndex("");
        setNotes([]);
    };

    const handleOpenAllNote = () => {
        setOpenAllNote(true);
        document.getElementById("context__menu").style.display = "none";
    };
    const handleCloseAllNote = () => {
        setOpenAllNote(false);
        setTxtID("");
        setTxtFrom("");
        setTxtIndex("");
        setNotes([]);
    };

    const handleCloseIgnore = () => {
        setOpenIgnore(false);
        setTxtID("");
        setTxtFrom("");
        setTxtIndex("");
        setIgnored(false);
    };

    const handleOpenFinalise = () => setOpenFinalise(true);
    const handleCloseFinalise = () => setOpenFinalise(false);

    const addNote = () => {
        handleOpenNote();
    };

    //===HANDLE OPEN/CLOSE FUNCTION END

    const handleDates = (site) => {
        finalised.splice(0);
        setSite(site);
        let data = {
            site: site,
        };
        axios
            .post(
                "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportCompiledDatesSQL",
                data
            )
            .then((res) => {
                setDates(res.data);
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].done === true) {
                        var dateX = moment(res.data[i].date).format(
                            "YYYY-MM-DD"
                        );
                        var dateY = moment(dateX).set({
                            hour: 5,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                        });
                        finalised.push(dateY);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleReport = () => {
        setSalesInfo(false);
        setInfoExpand(false);
        if (showMessage) {
            setShowMessage(false);
        }
        if (!finalisedButton) {
            setFinalisedButton(true);
        }

        let data = {
            site: site,
            date: date,
        };
        setLoader(true);

        axios
            .post(
                "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportSQL",
                data
            )
            .then((res) => {
                setHeaderData(res.data.headerData);
                if (res.data.compiled === true) {
                    setShowMessage(true);
                    setFinalisedButton(false);
                } else {
                    setFinalisedButton(true);
                }
                if (
                    res.data.epos.indv.length === 0 &&
                    res.data.prism.indv.length === 0
                ) {
                    setLoader(false);
                    setSalesInfo(true);
                    let button = document.getElementById("open__card__button");
                    button.style.display = "none";
                } else {
                    setLoader(false);
                    setSalesInfo(true);
                    setPrismTotal(res.data.prism.total.toFixed(2));
                    setPrismTxt(res.data.prism.indv);
                    setEposTotal(res.data.epos.total.toFixed(2));
                    setEposTxt(res.data.epos.indv);
                    setDiscrepancy(
                        (res.data.epos.total - res.data.prism.total).toFixed(2)
                    );
                    rel();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCloseTenderChange = () => {
        let menu = document.getElementById("context__menu");
        menu.style.display = "none";
        setTenderModal(false);
    };

    const handleTenderChange = () => {
        let menu = document.getElementById("context__menu");
        menu.style.display = "none";
        let data = {
            fuelId: txtID,
        };
        console.log(data);
        axios
            .post(
                `"https://nitropos-backend.nitrosolutions.co.uk/fuel/TenderUpdate`,
                data
            )
            .then((res) => {
                console.log(res);
                setTenderModal(false);

                handleReport();
            })
            .catch((err) => {
                console.log(err);
                setTenderAlert(true);
                setTimeout(() => {
                    setTenderAlert(false);
                }, 4000);
            });
    };

    const handleClick = (till, id, amount, index, date, staff, pos, sql) => {
        //Remove the right click context menu if it is on screen
        let menu = document.getElementById("context__menu");
        menu.style.display = "none";

        setTxtID("");
        var compDate = moment(date).format("YYYY-MM-DD");
        var dateFinal = true;
        for (let f = 0; f < finalised.length; f++) {
            var finalD = moment(finalised[f]).format("YYYY-MM-DD");
            if (moment(compDate).isSame(finalD)) {
                if (moment(date).isBefore(finalised[f])) {
                    dateFinal = false;
                }
            }
        }

        setFinalisedButton(true);
        if (matchedArray.length === 0) {
            matchedArray.push({
                from: till,
                ID: id,
                cost: parseFloat(amount).toFixed(2),
                time: date,
                i: index,
                site: site,
                staffId: staff,
                pos: pos,
                sqlId: sql,
            });

            let clickedOn = document.querySelectorAll(`#${till} tr`);
            for (let i = 0; i < clickedOn.length; i++) {
                if (i === index) {
                    //adds animation to first selected item
                    clickedOn[i].classList.add("selected");
                    break;
                }
            }
        } else if (
            matchedArray.length === 1 &&
            matchedArray[0].from != till &&
            matchedArray[0].cost === amount
        ) {
            matchedArray.push({
                from: till,
                ID: id,
                cost: amount,
                time: date,
                site: site,
                staffId: staff,
                pos: pos,
                sqlId: sql,
            });
            //Call to backend
            console.log("matched");
            let clickedOn = document.querySelectorAll(`#${till} tr`);
            for (let i = 0; i < clickedOn.length; i++) {
                if (i === index) {
                    clickedOn[i].classList.add("matched");
                    break;
                }
            }

            //removes animation from first item
            let firstItem = matchedArray[0];

            let firstItemTill = document.querySelectorAll(
                `#${firstItem.from} tr`
            );

            for (let t = 0; t < firstItemTill.length; t++) {
                if (t === firstItem.i) {
                    firstItemTill[t].classList.remove("selected");
                    firstItemTill[t].classList.add("matched");
                }
            }
            axios
                .post(
                    "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportMatchSQL",
                    matchedArray
                )
                .then((res) => {
                    matchedArray.splice(0, matchedArray.length);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (matchedArray[matchedArray.length - 1].sqlId === sql) {
            let unmatch = document.querySelectorAll(`#${till} tr`);
            for (let i = 0; i < unmatch.length; i++) {
                if (i === index) {
                    unmatch[i].classList.remove("selected");
                    break;
                }
            }

            if (matchedArray.length === 1) {
                matchedArray.splice(0, matchedArray.length);
            } else {
                matchedArray.splice(1, matchedArray.length - 1);
            }
        } //check for multiple selections
        else if (matchedArray.length >= 1 && matchedArray[0].from === till) {
            matchedArray.push({
                from: till,
                ID: id,
                cost: amount,
                time: date,
                i: index,
                site: site,
                staffId: staff,
                pos: pos,
                sqlId: sql,
            });

            let clickedOn = document.querySelectorAll(`#${till} tr`);

            for (let i = 0; i < clickedOn.length; i++) {
                if (i === index) {
                    //adds animation to first selected item
                    clickedOn[i].classList.add("selected");
                    break;
                }
            }
        } else if (matchedArray.length >= 2 && matchedArray[0].from != till) {
            let tillTotal = 0;

            for (let i = 0; i < matchedArray.length; i++) {
                let cost = parseFloat(matchedArray[i].cost);
                tillTotal = tillTotal + cost;
            }

            if (parseFloat(tillTotal).toFixed(2) == parseFloat(amount)) {
                matchedArray.push({
                    from: till,
                    ID: id,
                    cost: amount,
                    time: date,
                    i: index,
                    site: site,
                    staffId: staff,
                    pos: pos,
                    sqlId: sql,
                });

                for (let a = 0; a < matchedArray.length; a++) {
                    let itemTill = document.querySelectorAll(
                        `#${matchedArray[a].from} tr`
                    );
                    for (let t = 0; t < itemTill.length; t++) {
                        if (t === matchedArray[a].i) {
                            itemTill[t].classList.remove("selected");
                            itemTill[t].classList.add("matched");
                        }
                    }
                }

                axios
                    .post(
                        "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportMatchSQL",
                        matchedArray
                    )

                    .then((res) => {
                        matchedArray.splice(0, matchedArray.length);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                unmatched.push({
                    from: till,
                    ID: id,
                    cost: amount,
                    time: date,
                    i: index,
                    site: site,
                    staffId: staff,
                    pos: pos,
                    sqlId: sql,
                });

                let totalMatched = 0;

                for (let i = 0; i < matchedArray.length; i++) {
                    totalMatched =
                        totalMatched + parseFloat(matchedArray[i].cost);
                }

                setTotalMatchedArray(totalMatched);

                handleOpen();
            }
        } else if (
            matchedArray.length === 1 &&
            matchedArray[0].from != till &&
            matchedArray[0].cost != amount
        ) {
            unmatched.push({
                from: till,
                ID: id,
                cost: amount,
                time: date,
                i: index,
                site: site,
                staffId: staff,
                pos: pos,
                sqlId: sql,
            });
            let totalMatched = 0;

            for (let i = 0; i < matchedArray.length; i++) {
                totalMatched =
                    totalMatched + parseFloat(matchedArray[i].cost).toFixed(2);
            }
            setTotalMatchedArray(totalMatched);

            handleOpen();
        }
    };

    const handleCompile = () => {
        let mainData = {
            site: site,
            date: date,
            user: userEmail,
        };
        setFinalisedButton(false);
        setTinyLoader(true);

        axios
            .post(
                "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportSQL",
                mainData
            )
            .then((res) => {
                setLoader(false);
                setTinyLoader(false);

                let count = 0;

                for (let c = 0; c < res.data.prism.indv.length; c++) {
                    if (!res.data.prism.indv[c].matched) {
                        count = count + 1;
                    } else continue;
                }

                if (count === res.data.prism.indv.length) {
                    handleOpenFinalise();
                } else {
                    for (let i = 0; i < res.data.prism.indv.length; i++) {
                        if (res.data.prism.indv[i].matched == null) {
                            prismSorted.push(res.data.prism.indv[i]);
                        }
                    }

                    for (let e = 0; e < res.data.epos.indv.length; e++) {
                        if (res.data.epos.indv[e].ignored == true) {
                            continue;
                        } else if (res.data.epos.indv[e].matched == null) {
                            eposSorted.push(res.data.epos.indv[e]);
                        }
                    }

                    if (eposSorted.length != 0 || prismSorted.length != 0) {
                        setTinyLoader(false);
                        setFinalisedButton(true);
                        handleOpenDiscrepancies();
                    } else {
                        handleDates(site);
                        handleReport();
                        window.scrollTo(0, 0);
                        setFinalisedButton(true);
                    }
                }
            })
            .catch((err) => {
                setTinyLoader(false);
                setFinalisedButton(true);
            });
    };

    const handleArray = () => {
        if (matchedArray.length > 0) {
            let firstItemTill = document.querySelectorAll(
                `#${matchedArray[0].from} tr`
            );

            for (let t = 0; t < firstItemTill.length; t++) {
                for (let i = 0; i < matchedArray.length; i++) {
                    if (t === matchedArray[i].i) {
                        firstItemTill[t].classList.remove("selected");
                    }
                }
            }

            matchedArray.splice(0, matchedArray.length);
        }
    };

    const cancelForceMatch = () => {
        handleClose();

        if (matchedArray.length > 0) {
            let firstItemTill = document.querySelectorAll(
                `#${matchedArray[0].from} tr`
            );

            for (let t = 0; t < firstItemTill.length; t++) {
                for (let i = 0; i < matchedArray.length; i++) {
                    if (t === matchedArray[i].i) {
                        firstItemTill[t].classList.remove("selected");
                    }
                }
            }

            unmatched.splice(0, unmatched.length);
            matchedArray.splice(0, matchedArray.length);
        }
    };

    const pushForceMatch = () => {
        if (matchedReason === "" || matchedReason === "--Select a reason--") {
            setMessage("Please Select a reason");
            setReasonAlert(true);
            setTimeout(() => {
                setReasonAlert(false);
            }, 2500);
        } else {
            matchedArray.push(unmatched[0]);

            let data = {
                reason: matchedReason,
                user: userEmail,
                difference:
                    totalMatchedArray -
                    parseFloat(unmatched[0].cost).toFixed(2),
                matched: matchedArray,
            };
            axios
                .post(
                    "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportForceMatchSQL",
                    data
                )
                .then((res) => {
                    handleClose();

                    if (data.reason === "BP Points") {
                        for (let a = 0; a < matchedArray.length; a++) {
                            let itemTill = document.querySelectorAll(
                                `#${matchedArray[a].from} tr`
                            );
                            for (let t = 0; t < itemTill.length; t++) {
                                if (t === matchedArray[a].i) {
                                    itemTill[t].classList.remove("selected");
                                    itemTill[t].classList.add("matched");
                                }
                            }
                        }
                        let secondClick = document.querySelectorAll(
                            `#${unmatched[0].from} tr`
                        );
                        for (let i = 0; i < secondClick.length; i++) {
                            if (i === unmatched[0].i) {
                                secondClick[i].classList.add("matched");
                                break;
                            }
                        }
                    } else {
                        for (let a = 0; a < matchedArray.length; a++) {
                            let itemTill = document.querySelectorAll(
                                `#${matchedArray[a].from} tr`
                            );
                            for (let t = 0; t < itemTill.length; t++) {
                                if (t === matchedArray[a].i) {
                                    itemTill[t].classList.remove("selected");
                                    itemTill[t].classList.add(
                                        "matchedDiscrepancy"
                                    );
                                }
                            }
                        }

                        let secondClick = document.querySelectorAll(
                            `#${unmatched[0].from} tr`
                        );
                        for (let i = 0; i < secondClick.length; i++) {
                            if (i === unmatched[0].i) {
                                secondClick[i].classList.add(
                                    "matchedDiscrepancy"
                                );
                                break;
                            }
                        }
                    }

                    setMatchedReason("");

                    unmatched.splice(0, unmatched.length);
                    matchedArray.splice(0, matchedArray.length);
                    handleDates(site);
                })
                .catch((err) => {
                    setMessage("Error on sending, please try again");
                    setReasonAlert(true);
                    setTimeout(() => {
                        setReasonAlert(false);
                    }, 3000);
                });
        }
    };

    const handleCancelFinaliase = () => {
        setEposSorted([]);
        setPrismSorted([]);

        handleCloseCDiscrepancies();
    };

    const finaliseDiscrepancies = () => {
        let data = {
            prismDiscrepancies: prismSorted,
            eposDiscrepancies: eposSorted,
            user: userEmail,
            site: site,
        };
        axios
            .post(
                "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportCompileSQL",
                data
            )
            .then((res) => {
                eposSorted.splice(0, eposSorted.length);
                prismSorted.splice(0, prismSorted.length);
                handleCloseCDiscrepancies();
                handleDates(site);
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleRightClick = (e, id, from, index, notes, ignored) => {
        console.log(e, id, from, index, notes, ignored);
        const xPos = e.pageX + "px";
        const yPos = e.pageY + "px";
        let menu = document.getElementById("context__menu");
        let scope = document.querySelector("body");

        scope.addEventListener("contextmenu", (e) => {
            e.preventDefault();
            menu.style = `display: inline-block; position: absolute; top: ${yPos}; left: ${xPos};`;
        });
        setTxtID(id);
        setTxtFrom(from);
        setTxtIndex(index);
        setNotes(notes);
        setIgnored(ignored);
        document.addEventListener("mousedown", handleClickOutside);
    };
    const handleClickOutside = (e) => {
        let menu = document.getElementById("context__menu");
        if (!menu.contains(e.target)) {
            menu.style.display = "none";
            document.removeEventListener("mousedown", handleClickOutside);
        }
    };

    const sendNote = async () => {
        const data = {
            note: note,
            user: userEmail,
            sqlId: txtID,
            from: txtFrom,
        };
        axios
            .post(
                "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportAddNote",
                data
            )
            .then((res) => {
                setOpenNote(false);
                document.getElementById("context__menu").style.display = "none";
                let selection = document.querySelectorAll(`#${txtFrom} tr`);
                for (let i = 0; i < selection.length; i++) {
                    if (i === txtIndex) {
                        selection[i].classList.add("noteAdded");
                    }
                }
                setTxtID("");
                setTxtFrom("");
                setTxtIndex("");
                let data = {
                    site: site,
                    date: date,
                };
                axios
                    .post(
                        "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportSQL",
                        data
                    )
                    .then((res) => {
                        setPrismTotal(res.data.prism.total.toFixed(2));
                        setPrismTxt(res.data.prism.indv);
                        setEposTotal(
                            setEposTotal(res.data.epos.total.toFixed(2))
                        );
                        setEposTxt(res.data.epos.indv);
                        setDiscrepancy(
                            (
                                res.data.epos.total - res.data.prism.total
                            ).toFixed(2)
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUnMatch = (epos, prism) => {
        let data = {
            eposArray: epos,
            prismArray: prism,
        };
        axios
            .post(
                "https://nitropos-backend.nitrosolutions.co.uk/fuel/unMatchSQL",
                data
            )
            .then((res) => {
                handleCloseUnMatch();
                let reload = {
                    site: site,
                    date: date,
                };
                axios
                    .post(
                        "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportSQL",
                        reload
                    )
                    .then((res) => {
                        setPrismTotal(res.data.prism.total.toFixed(2));
                        setPrismTxt(res.data.prism.indv);
                        setEposTotal(res.data.epos.total.toFixed(2));
                        setEposTxt(res.data.epos.indv);
                        setDiscrepancy(
                            (
                                res.data.epos.total - res.data.prism.total
                            ).toFixed(2)
                        );
                        rel();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleIgnore = () => {
        document.getElementById("context__menu").style.display = "none";

        let data = {
            sql: txtID,
            ignored: !ignored,
            user: userEmail,
        };
        axios
            .post(
                "https://nitropos-backend.nitrosolutions.co.uk/fuel/updateIgnore",
                data
            )
            .then((res) => {
                let data = {
                    site: site,
                    date: date,
                };
                setLoader(true);
                axios
                    .post(
                        "https://nitropos-backend.nitrosolutions.co.uk/fuel/fuelReportSQL",
                        data
                    )
                    .then((res) => {
                        if (
                            finalised.includes(
                                moment(date).format("YYYY-MM-DD")
                            )
                        ) {
                            setShowMessage(true);
                            setFinalisedButton(false);
                        } else {
                            setFinalisedButton(true);
                        }
                        if (
                            res.data.epos.indv.length === 0 &&
                            res.data.prism.indv.length === 0
                        ) {
                            setLoader(false);
                            setSalesInfo(true);
                            let button =
                                document.getElementById("open__card__button");
                            button.style.display = "none";
                        } else {
                            setLoader(false);
                            setSalesInfo(true);
                            setPrismTotal(res.data.prism.total.toFixed(2));
                            setPrismTxt(res.data.prism.indv);
                            setEposTotal(res.data.epos.total.toFixed(2));
                            setEposTxt(res.data.epos.indv);
                            setDiscrepancy(
                                (
                                    res.data.epos.total - res.data.prism.total
                                ).toFixed(2)
                            );
                            rel();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const finaliseNoMatch = () => {
        const data = {
            epos: eposTxt,
            prism: prismTxt,
            user: userEmail,
        };
        axios
            .post(
                `https://nitropos-backend.nitrosolutions.co.uk/fuel/forceMatchDay`,
                data
            )
            .then((res) => {
                handleCloseFinalise();
                handleDates(site);
                setInfoExpand(false);
                window.scrollTo(0, 0);
                rel();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const groupedTransactions = Object.values(
        eposTxt.reduce((acc, transaction) => {
            const { id } = transaction;
            if (!acc[id]) {
                acc[id] = {
                    ...transaction,
                    tenderTypes: [],
                    totalCost: 0,
                };
            }
            acc[id].tenderTypes.push(transaction.tenderTypeName);
            acc[id].totalCost += parseFloat(transaction.cost);
            return acc;
        }, {})
    );

    return (
        <main className="fuel-reports fuel">
            <h1 className="main-heading ">Fuel Reports</h1>
            <section className="search-area">
                <Tooltip title="If matching doesn't work click here and then try again">
                    <Button
                        onClick={handleArray}
                        className="help-icon"
                        variant="contained"
                        color="primary"
                    >
                        <QuestionMarkIcon fontSize="large" />
                    </Button>
                </Tooltip>
                <select onChange={(e) => handleDates(e.target.value)}>
                    <option value="">Select a Site</option>
                    <option>Autoport</option>
                    <option>Bearsden</option>
                    <option>Braeside</option>
                    <option>Bridgeton</option>
                    <option>Rutherglen</option>
                </select>
                <DatePicker
                    onChange={(e) => setDate(e)}
                    className="date-picker"
                    maxDate={new Date()} // Sets the maximum date to today
                    tileClassName={({ date, view }) =>
                        view === "month" &&
                        dates.some(
                            (e) => e.date === moment(date).format("YYYY-MM-DD")
                        )
                            ? dates.some(
                                  (e) =>
                                      e.date ===
                                          moment(date).format("YYYY-MM-DD") &&
                                      e.done
                              )
                                ? "Compile_Done"
                                : "Compile__Error"
                            : null
                    }
                    selected={date}
                    value={date}
                />
                <Button
                    onClick={() => handleReport()}
                    variant="contained"
                    color="primary"
                    disabled={site === "" || date === ""}
                    className="search-btn"
                >
                    <SearchIcon fontSize="large" />
                </Button>
            </section>
            {!loader ? (
                salesInfo ? (
                    <div className="info">
                        <div className="info-card">
                            <div className="single-info">
                                <h2>EposNOW</h2>
                                <h3>£{eposTotal}</h3>
                            </div>
                            <div className="single-info">
                                <h2>Prism</h2>
                                <h3>£{prismTotal}</h3>
                            </div>
                            <div className="single-info">
                                <h2>Difference</h2>
                                <h3>£{parseFloat(discrepancy).toFixed(2)}</h3>
                            </div>
                            <div className="single-info">
                                <h2>No. of Drive-offs</h2>
                                <h3>{headerData.driveOff}</h3>
                            </div>
                            <div className="single-info">
                                <h2>Suspended</h2>
                                <h3>{headerData.suspended}</h3>
                            </div>
                        </div>

                        <div className="info__button">
                            {showMessage ? (
                                <h4
                                    style={{
                                        color: "#BF0426",
                                        textTransform: "uppercase",
                                        fontSize: "1.5rem",
                                    }}
                                >
                                    This day has been finalised. You cannot
                                    longer edit it.
                                </h4>
                            ) : (
                                <></>
                            )}
                            <div className="info__expand">
                                <table id="e">
                                    <thead>
                                        <th>EPOS - Transaction ID - (Till)</th>
                                        <th>EPOS - Time</th>
                                        <th>Amount</th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        {groupedTransactions.map(function (
                                            d,
                                            i
                                        ) {
                                            let matched = "";
                                            let notes = "";
                                            let ignore = "";
                                            let icons = [];
                                            if (
                                                d.matched != null &&
                                                d.discrepancy === false
                                            ) {
                                                matched = "matched";
                                            } else if (
                                                d.matched != null &&
                                                d.discrepancy === true
                                            ) {
                                                matched = "matchedDiscrepancy";
                                            }
                                            if (d.notes != null) {
                                                notes = "noteAdded ";
                                            }
                                            if (d.ignored === true) {
                                                ignore = "ignored ";
                                            }
                                            d.tenderTypes.forEach(
                                                (tenderTypeName) => {
                                                    switch (tenderTypeName) {
                                                        case "Cash":
                                                            icons.push(
                                                                <PaymentsIcon
                                                                    sx={{
                                                                        color: "#48484d",
                                                                        fontSize:
                                                                            "17px",
                                                                    }}
                                                                />
                                                            );
                                                            break;
                                                        case "UNKNOWN":
                                                            icons.push(
                                                                <QuestionMarkIcon
                                                                    sx={{
                                                                        color: "#48484d",
                                                                        fontSize:
                                                                            "17px",
                                                                    }}
                                                                />
                                                            );
                                                            break;
                                                        default:
                                                            icons.push(
                                                                <CreditCardIcon
                                                                    sx={{
                                                                        color: "#48484d",
                                                                        fontSize:
                                                                            "17px",
                                                                    }}
                                                                />
                                                            );
                                                    }
                                                }
                                            );
                                            return (
                                                <tr
                                                    onClick={() =>
                                                        handleClick(
                                                            d.from,
                                                            d.id,
                                                            parseFloat(
                                                                d.totalCost
                                                            ).toFixed(2),
                                                            i,
                                                            d.time,
                                                            d.staffId,
                                                            d.till,
                                                            d.sqlId
                                                        )
                                                    }
                                                    className={`${matched} ${notes} ${ignore}`}
                                                    onContextMenu={(e) =>
                                                        handleRightClick(
                                                            e,
                                                            d.sqlId,
                                                            d.from,
                                                            i,
                                                            d.notes,
                                                            d.ignored,
                                                            parseFloat(
                                                                d.totalCost
                                                            ).toFixed(2)
                                                        )
                                                    }
                                                >
                                                    <th>
                                                        {d.id} - {d.till}
                                                    </th>
                                                    <th>
                                                        {new Date(
                                                            d.time
                                                        ).toLocaleString()}
                                                    </th>
                                                    <th>
                                                        {d.totalCost.toFixed(2)}
                                                    </th>
                                                    <th>{icons}</th>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <table id="p">
                                    <thead>
                                        <th>Amount</th>
                                        <th>PRISM - Time</th>
                                        <th>PRISM - Transaction ID - (POS)</th>
                                        <th>Pump - (Grade)</th>
                                    </thead>
                                    <tbody>
                                        {prismTxt.map(function (d, i) {
                                            let matched = "";
                                            let notes = "";
                                            let points = "";
                                            if (
                                                d.matched != null &&
                                                d.discrepancy === false
                                            ) {
                                                matched = "matched";
                                            } else if (
                                                d.matched != null &&
                                                d.discrepancy === true
                                            ) {
                                                matched = "matchedDiscrepancy ";
                                            }
                                            if (d.notes != null) {
                                                notes = "noteAdded ";
                                            }
                                            if (d.BPpoints === true) {
                                                points = "bp__points ";
                                            }
                                            return (
                                                <tr
                                                    className={`${matched} ${notes} ${points}`}
                                                    onClick={() =>
                                                        handleClick(
                                                            d.from,
                                                            d.id,
                                                            d.cost,
                                                            i,
                                                            d.time,
                                                            d.staffId,
                                                            d.till,
                                                            d.sqlId
                                                        )
                                                    }
                                                    onContextMenu={(e) =>
                                                        handleRightClick(
                                                            e,
                                                            d.sqlId,
                                                            d.from,
                                                            i,
                                                            d.notes,
                                                            d.ignored,
                                                            d.cost
                                                        )
                                                    }
                                                >
                                                    <th>{d.cost}</th>
                                                    <th>
                                                        {new Date(
                                                            d.time
                                                        ).toLocaleString()}
                                                    </th>
                                                    <th>
                                                        {d.id} - ({d.till}){" "}
                                                        {d.unSuspendedId ? (
                                                            <Tooltip title="Unsuspended">
                                                                <RestorePageIcon color="warning" />
                                                            </Tooltip>
                                                        ) : null}
                                                    </th>
                                                    <th>{d.fuel}</th>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <Modal open={tenderModal}>
                                    <Box
                                        sx={finalStyle}
                                        className="tender_modal"
                                    >
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h4"
                                            component="h2"
                                        >
                                            Tender Change
                                        </Typography>
                                        <Typography
                                            id="modal-modal-description"
                                            sx={{ mt: 2 }}
                                            className="modal_content"
                                        >
                                            You are about to change the tender
                                            type from GBP to Key Fuels. Are you
                                            sure?
                                        </Typography>
                                        <section className="alert_section">
                                            {tenderAlert ? (
                                                <Alert severity="error">
                                                    Something went wrong -
                                                    Please try again
                                                </Alert>
                                            ) : null}
                                        </section>
                                        <section className="btn_section">
                                            <Button
                                                onClick={handleTenderChange}
                                                variant="contained"
                                            >
                                                Yes, I'm sure
                                            </Button>
                                            <Button
                                                onClick={
                                                    handleCloseTenderChange
                                                }
                                                variant="contained"
                                                color="error"
                                            >
                                                No, go back
                                            </Button>
                                        </section>
                                    </Box>
                                </Modal>
                                <Modal
                                    open={open}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                        >
                                            <h2
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                Force Match
                                            </h2>
                                        </Typography>
                                        <Typography
                                            id="modal-modal-description"
                                            sx={{ mt: 2 }}
                                        >
                                            <div className="force__match">
                                                {reasonAlert ? (
                                                    <Alert
                                                        severity="error"
                                                        sx={{
                                                            width: "90%",
                                                            margin: "auto",
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                        }}
                                                    >
                                                        {message}
                                                    </Alert>
                                                ) : (
                                                    <></>
                                                )}
                                                <h4>
                                                    Are you sure you want to
                                                    Match?
                                                </h4>
                                                {matchedArray.length != 0 &&
                                                unmatched.length != 0 ? (
                                                    <>
                                                        <ul>
                                                            {matchedArray.map(
                                                                function (
                                                                    d,
                                                                    i
                                                                ) {
                                                                    return (
                                                                        <li>
                                                                            Item
                                                                            number:{" "}
                                                                            {i +
                                                                                1}{" "}
                                                                            ={" "}
                                                                            {
                                                                                d.cost
                                                                            }
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                        <p>
                                                            Unmatched Item = £
                                                            {unmatched[0].cost}
                                                        </p>
                                                        <p>
                                                            Difference = £
                                                            {(
                                                                totalMatchedArray -
                                                                parseFloat(
                                                                    unmatched[0]
                                                                        .cost
                                                                ).toFixed(2)
                                                            ).toLocaleString(
                                                                "en-GB"
                                                            )}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <select
                                                    onChange={(e) =>
                                                        setMatchedReason(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option>
                                                        --Select a reason--
                                                    </option>
                                                    <option>
                                                        Staff Error Misscharged
                                                    </option>
                                                    <option>
                                                        Wrong pump charged
                                                    </option>
                                                    <option>BP Points</option>
                                                    <option>
                                                        System Error
                                                    </option>
                                                </select>
                                                <div className="force__buttons">
                                                    <Button
                                                        variant="outlined"
                                                        color="success"
                                                        sx={{
                                                            width: "10vw",
                                                        }}
                                                        onClick={() =>
                                                            pushForceMatch()
                                                        }
                                                    >
                                                        YES
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        sx={{
                                                            width: "10vw",
                                                        }}
                                                        onClick={() =>
                                                            cancelForceMatch()
                                                        }
                                                    >
                                                        CANCEL
                                                    </Button>
                                                </div>
                                            </div>
                                        </Typography>
                                    </Box>
                                </Modal>
                            </div>
                            {finalisedButton ? (
                                <ColorButton
                                    variant="contained"
                                    onClick={() => handleCompile()}
                                >
                                    Finalise
                                </ColorButton>
                            ) : (
                                <></>
                            )}
                            {tinyLoader ? (
                                <div className="tiny__loader">
                                    <Loader />
                                </div>
                            ) : (
                                <></>
                            )}
                            <Modal
                                open={openDiscrepancies}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={discrepancyStyle}>
                                    <Typography
                                        id="modal-modal-title"
                                        variant="h6"
                                        component="h2"
                                    >
                                        <h2
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            Finalise Discrepancies
                                        </h2>
                                    </Typography>
                                    <Typography
                                        id="modal-modal-description"
                                        sx={{ mt: 2 }}
                                    >
                                        <div className="force__match">
                                            {eposSorted.length != 0 ||
                                            prismSorted.length != 0 ? (
                                                <>
                                                    <p>
                                                        Are you sure you want to
                                                        Finalise with these
                                                        discrepancies?
                                                    </p>
                                                    <ul>
                                                        {eposSorted.length !=
                                                        0 ? (
                                                            eposSorted.map(
                                                                function (
                                                                    d,
                                                                    i
                                                                ) {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                i
                                                                            }
                                                                        >
                                                                            EPOS
                                                                            ID{" "}
                                                                            {
                                                                                d.id
                                                                            }{" "}
                                                                            -
                                                                            value
                                                                            = £
                                                                            {
                                                                                d.cost
                                                                            }
                                                                        </li>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {prismSorted.length !=
                                                        0 ? (
                                                            prismSorted.map(
                                                                function (
                                                                    d,
                                                                    i
                                                                ) {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                i
                                                                            }
                                                                        >
                                                                            PRISM
                                                                            ID{" "}
                                                                            {
                                                                                d.id
                                                                            }{" "}
                                                                            -
                                                                            value
                                                                            = £
                                                                            {
                                                                                d.cost
                                                                            }
                                                                        </li>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </ul>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <div className="force__buttons">
                                                <Button
                                                    variant="outlined"
                                                    color="success"
                                                    sx={{
                                                        width: "10vw",
                                                    }}
                                                    onClick={() =>
                                                        finaliseDiscrepancies()
                                                    }
                                                >
                                                    YES
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    sx={{
                                                        width: "10vw",
                                                    }}
                                                    onClick={() =>
                                                        handleCancelFinaliase()
                                                    }
                                                >
                                                    CANCEL
                                                </Button>
                                            </div>
                                        </div>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                    </div>
                ) : (
                    <></>
                )
            ) : (
                <Loader />
            )}
            <div id="context__menu" style={{ display: "none" }}>
                <ul>
                    <li onClick={() => addNote()}>Add Note</li>
                    {notes && notes.length != 0 ? (
                        <li onClick={() => handleOpenAllNote()}>See Notes</li>
                    ) : null}
                    <li onClick={() => handleOpenUnMatch()}>Un-Match</li>
                    {txtFrom === "p" ? (
                        <li onClick={() => setTenderModal(true)}>
                            Change Tender
                        </li>
                    ) : null}

                    {txtFrom === "e" && ignored === false ? (
                        <li onClick={() => handleIgnore()}>Ignore</li>
                    ) : txtFrom === "e" && ignored === true ? (
                        <li onClick={() => handleIgnore()}>Un-ignored</li>
                    ) : null}
                </ul>

                <Modal
                    open={openNote}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={noteStyle}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            <h2 style={{ textAlign: "center" }}>Add a Note</h2>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <div className="force__match">
                                <textarea
                                    cols={50}
                                    rows={6}
                                    onChange={(e) => setNote(e.target.value)}
                                    style={{ border: "1px solid #000" }}
                                    placeholder="Type your note"
                                />
                                <div className="force__buttons">
                                    <Button
                                        variant="outlined"
                                        color="success"
                                        sx={{ width: "10vw" }}
                                        onClick={() => sendNote()}
                                    >
                                        YES
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{ width: "10vw" }}
                                        onClick={() => handleCloseNote()}
                                    >
                                        CANCEL
                                    </Button>
                                </div>
                            </div>
                        </Typography>
                    </Box>
                </Modal>

                <Modal
                    open={openAllNote}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    onClose={handleCloseAllNote}
                >
                    <IndvNotes notes={notes} />
                </Modal>

                <Modal
                    open={openUnMatch}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <UnMatchModal
                        close={handleCloseUnMatch}
                        id={txtID}
                        from={txtFrom}
                        match={handleUnMatch}
                    />
                </Modal>

                <Modal
                    open={openIgnore}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <IgnoreModal
                        handleCloseIgnore={handleCloseIgnore}
                        handleIgnore={handleIgnore}
                        ignored={ignored}
                    />
                </Modal>

                <Modal
                    open={openFinalise}
                    // onClose={handleCloseFinalise}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={finalStyle}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            DO YOU WANT TO FINALISE WITHOUT MATCHING DATA?
                        </Typography>
                        <Stack
                            spacing={2}
                            direction="row"
                            sx={{ width: "30%", margin: "2% auto" }}
                        >
                            <Button
                                variant="outlined"
                                onClick={handleCloseFinalise}
                                color="error"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => finaliseNoMatch()}
                            >
                                Submit
                            </Button>
                        </Stack>
                    </Box>
                </Modal>
            </div>
        </main>
    );
}
