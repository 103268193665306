//When adding routes please add to the mobile version
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import CodeIcon from "@mui/icons-material/Code";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import "../styles/navbar.scss";
import RuleIcon from "@mui/icons-material/Rule";
import axios from "axios";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import DifferenceIcon from '@mui/icons-material/Difference';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
//mobile menu
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Typography } from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";

export default function ManagerNav(props) {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [openClaims, setOpenClaims] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [poAuth, setPoAuth] = useState(false);
  const [requests, setRequests] = useState(0);

  const Logout = () => {
    localStorage.setItem("auth-token", "");
    localStorage.setItem("role", "");
    localStorage.setItem("userDetails", "");
    localStorage.removeItem("alert-shown");
    navigate("/");
  };

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setRole(user.user.role);
    setPoAuth(user.user.poAdmin);
    let getToken = localStorage.getItem("auth-token");

    //checks claims
    axios
      .post(`https://nitropos-backend.nitrosolutions.co.uk/claims/active`, {
        site: user.user.sites,
      })
      .then((res) => {
        if (res.data.claims > 0) {
          setShowWarning(true);
          setOpenClaims(res.data.claims);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    //checks holidays requests
    axios
      .post(
        "https://hr-be.racetrackpitstop.co.uk/holiday/admin/pending/new",
        {
          user: user.user.user,
        },
        { headers: { "x-auth-token": getToken } }
      )
      .then((res) => {
        if (res.data.length > 0) {
          setRequests(res.data.length);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (getToken === "" || getToken === null || getToken === undefined) {
      Logout();
    }
  }, []);

  return (
    <nav
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <img src={Logo} alt="logo" onClick={() => navigate("/managerZone")} />
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Typography {...bindTrigger(popupState)}>
              <MenuOpenIcon fontSize="large" />
            </Typography>
            <Menu {...bindMenu(popupState)}>
              {role === "Developer" ? (
                <MenuItem onClick={() => navigate("/dev-zone")}>
                  <span className="mobile__icons">
                    <CodeIcon />
                    Dev Zone
                  </span>
                </MenuItem>
              ) : (
                <></>
              )}
              {role === "Manager" || role === "Developer" ? (
                <MenuItem onClick={() => navigate("/home")}>
                  <span className="mobile__icons">
                    <AdminPanelSettingsIcon />
                    Admin Area
                  </span>
                </MenuItem>
              ) : null}

              <MenuItem
                onClick={() =>
                  navigate("/purchase-order-admin")
                }
                disabled={!poAuth}
              >
                <span className="mobile__icons">
                  <ShoppingBasketIcon /> PO Admin{" "}
                  {poAuth ? null : (
                    <span
                      style={{
                        fontSize: "0.8rem",
                        color: "red",
                      }}
                    >
                      (No Access)
                    </span>
                  )}
                </span>
              </MenuItem>
              {role === "Manager" ||
                role === "Store Manager" ||
                role === "Developer" ||
                role === "Food Manager" ? (
                <>
                  <MenuItem onClick={() => navigate("/staff-management")}>
                    <span className="mobile__icons">
                      <Diversity3Icon />
                      Staff Management
                      {requests > 0 ? <span id="hols">{requests}</span> : <></>}
                    </span>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/managerZone/eod")}>
                    <span className="mobile__icons">
                      <CurrencyPoundIcon />
                      EOD
                    </span>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/managerZone/claims")}>
                    {showWarning ? (
                      <div className="alert">
                        <span>{openClaims}</span>
                      </div>
                    ) : (
                      <></>
                    )}
                    <span className="mobile__icons">
                      <RuleIcon />
                      Claims
                    </span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/managerZone/wastageReport");
                    }}
                  >
                    <span className="mobile__icons">
                      <FolderDeleteIcon />
                      Wastage Reports
                    </span>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/cctv-status")}>
                    <span className="mobile__icons">
                      <QrCodeScannerIcon />
                      CCTV Status Change
                    </span>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/managerZone/pos-explorer")}>
                    <span className="mobile__icons">
                      <TravelExploreIcon />
                      POS Explorer
                    </span>
                  </MenuItem>
                </>
              ) : null}

              {role === "Supervisor" ?
                <>
                  <MenuItem
                    onClick={() => {
                      navigate("/managerZone/wastageReport");
                    }}
                  >
                    <span className="mobile__icons">
                      <FolderDeleteIcon />
                      Wastage Reports
                    </span>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/staff-management")}>
                    <span className="mobile__icons">
                      <Diversity3Icon />
                      Staff Management
                    </span>
                  </MenuItem></>
                : <></>
              }
              {/* <MenuItem onClick={() => navigate("/managerZone/add-epos-item")}>
                <span className="mobile__icons">
                  <PostAddIcon />
                  Add EPOS Item
                </span>
              </MenuItem> */}
              <MenuItem onClick={() => navigate("/managerZone/epos-products")}>
                <span className="mobile__icons">
                  <ProductionQuantityLimitsIcon />
                  EPOS Products
                </span>
              </MenuItem>
              <MenuItem onClick={() => navigate("/managerZone/duplicates")}>
                <span className="mobile__icons">
                  <DifferenceIcon />
                  EPOS Duplicates
                </span>
              </MenuItem>
              <MenuItem onClick={() => navigate("/managerZone/no-supplier")}>
                <span className="mobile__icons">
                  <LocalShippingIcon />
                  EPOS No Supplier
                </span>
              </MenuItem>
              <MenuItem onClick={() => navigate("/managerZone/no-cost")}>
                <span className="mobile__icons">
                  <PriceChangeIcon />
                  EPOS Zero Cost
                </span>
              </MenuItem>
              <MenuItem onClick={() => Logout()}>
                <span className="mobile__icons">
                  <LogoutIcon />
                  Logout
                </span>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </nav>
  );
}
