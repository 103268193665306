import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
//MUI
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { Modal } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
//Styling
import "./cctv-admin-home.scss";
//Components
import CCTVAdminRow from "./CCTVAdminRow";
import Loader from "../../animations/Loader";
import CCTVAdminHelp from "./CCTVAdminHelp";
//Utils
import CCTVAdminForm from "./CCTVAdminForm";

export default function CCTVAdminHome() {
    const url = process.env.REACT_APP_ANPR_URL;
    //User
    const [user, setUser] = useState<any>({});
    //CCTV requests
    const [fixedRequests, setFixedRequests] = useState<any>([]);
    const [displayedRequests, setDisplayedRequests] = useState<any>([]);
    //Sites
    const [sites, setSites] = useState<any[]>([]);
    const [selectedSite, setSelectedSite] = useState<string>("");
    //Statuses
    const [statuses, setStatuses] = useState<any[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<number>(0);
    //Types
    const [types, setTypes] = useState<any[]>([]);
    const [selectedType, setSelectedType] = useState<number>(0);
    //Search Fields
    const [regSearch, setRegSearch] = useState<string>("");
    const [crimeRefSearch, setCrimeRefSearch] = useState<string>("");
    const [dateRangeStart, setDateRangeStart] = useState<string>("");
    const [dateRangeEnd, setDateRangeEnd] = useState<string>("");
    //Modals
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [openHelp, setOpenHelp] = useState<boolean>(false);
    //Alerts & Loader
    const [disable, setDisable] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        getUserDetails();
        fetchRequests();
        fetchSites();
        fetchStatuses();
        fetchTypes();
    }, []);

    const getUserDetails = () => {
        let _user = localStorage.getItem("userDetails");
        if (_user) {
            _user = JSON.parse(_user);
            setUser(_user);
        }
    };

    const fetchRequests = async () => {
        setLoader(true);
        let _requests: any[] = [];
        await axios
            .post(`${url}/cctv-system/submissions/details`)
            .then((res) => {
                _requests = res.data;
            })
            .catch((err) => {
                console.log(err);
            });

        await axios
            .get(`${url}/cctv-system/submissions/chase`)
            .then((res) => {
                let _newRequests: any[] = [];

                for (let i = 0; i < _requests.length; i++) {
                    let _req = _requests[i];
                    for (let j = 0; j < res.data.length; j++) {
                        let _unactioned = res.data[j];
                        if (_req.requestId === _unactioned.requestId) {
                            _req.unactionIn7Days = true;
                        }
                    }
                    _newRequests.push(_req);
                }

                setFixedRequests(_newRequests);
                setDisplayedRequests(_newRequests);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    };

    const fetchSites = async () => {
        await axios
            .get(`${url}/cctv-system/sites/all`)
            .then((res) => {
                setSites(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchStatuses = async () => {
        await axios
            .get(`${url}/cctv-system/statuses`)
            .then((res) => {
                setStatuses(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchTypes = async () => {
        await axios
            .get(`${url}/cctv-system/request-types`)
            .then((res) => {
                setTypes(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleRefresh = (): void => {
        setDisable(true);
        setDateRangeStart("");
        setDateRangeEnd("");
        setCrimeRefSearch("");
        setRegSearch("");
        setSelectedStatus(0);
        setSelectedSite("");
        setSelectedType(0);
        setDisable(false);
        fetchRequests();
    };
    const clearFilters = (): void => {
        setSelectedStatus(0);
        setSelectedSite("");
        setSelectedType(0);
    };
    const clearSearches = (): void => {
        setDateRangeStart("");
        setDateRangeEnd("");
        setCrimeRefSearch("");
        setRegSearch("");
    };

    const handleDateSearch = (date: string, type: string): void => {
        //Determine if date change is the start or end
        if (type === "start") {
            setDateRangeStart(date);
            //If end date set then do lookup
            if (dateRangeEnd !== "") {
                let _filteredArray = fixedRequests.filter(
                    (request: any, i: number) => {
                        let _formattedDate = moment(request.date).format(
                            "YYYY-MM-DD"
                        );
                        return (
                            _formattedDate >= date &&
                            _formattedDate <= dateRangeEnd
                        );
                    }
                );
                setDisplayedRequests(_filteredArray);
            }
        } else {
            setDateRangeEnd(date);
            //If start date set then do lookup
            if (dateRangeStart !== "") {
                let _filteredArray = fixedRequests.filter(
                    (request: any, i: number) => {
                        let _formattedDate = moment(request.date).format(
                            "YYYY-MM-DD"
                        );
                        return (
                            _formattedDate >= dateRangeStart &&
                            _formattedDate <= date
                        );
                    }
                );
                setDisplayedRequests(_filteredArray);
            }
        }
    };

    const handleRegCheck = (): void => {
        //Will clear filter then check reg entered against request reg in lower case
        clearFilters();
        setCrimeRefSearch("");
        setDateRangeStart("");
        setDateRangeEnd("");
        let _lowerCaseReg = regSearch.toLowerCase();
        let _filteredArray: any = fixedRequests.filter(
            (request: any, i: number) => {
                return (
                    request.plate &&
                    request.plate.toLowerCase().includes(_lowerCaseReg)
                );
            }
        );
        setDisplayedRequests(_filteredArray);
    };

    const handleCrimeRefCheck = (): void => {
        //Will clear filter then check crime ref entered against request crime ref in lower case
        clearFilters();
        setRegSearch("");
        setDateRangeStart("");
        setDateRangeEnd("");
        let _lowerCaseCrimeRef = crimeRefSearch.toLowerCase();
        let _filteredArray: any = fixedRequests.filter(
            (request: any, i: number) => {
                return (
                    request.crimeReference &&
                    request.crimeReference
                        .toLowerCase()
                        .includes(_lowerCaseCrimeRef)
                );
            }
        );
        setDisplayedRequests(_filteredArray);
    };

    //Will filter displayed requests using site, type and status filters
    const handleFilter = (value: any, field: string): void => {
        let _field = field.toString();
        clearSearches();

        let filterData = {
            requestType: _field === "type" ? value : selectedType,
            pinNumber: _field === "site" ? value : selectedSite,
            statusId: _field === "status" ? value : selectedStatus,
        };

        //Remove any key/value pairs from the filtered data where the value is 0 (default)
        const removeZeros = (item: any) =>
            Object.keys(item)
                .filter((key) => item[key] !== 0 && item[key] !== "")
                .reduce((newObj: any, key) => {
                    newObj[key] = item[key];
                    return newObj;
                }, {});
        const _result = removeZeros(filterData);

        //Create a new filter array of the key value pairs that have to be filtered
        let filteredArray: any = [];
        for (const [key, value] of Object.entries(_result)) {
            filteredArray.push({ key: key, value: value });
        }

        //Loop through filter array and set new array
        let finalFiltered = fixedRequests;
        for (let index = 0; index < filteredArray.length; index++) {
            finalFiltered = finalFiltered.filter(
                (order: any) =>
                    order[filteredArray[index].key] ==
                    filteredArray[index].value
            );
        }

        //Set Current filter selects
        if (_field === "type") {
            setSelectedType(value);
        } else if (_field === "site") {
            setSelectedSite(value);
        } else if (_field === "status") {
            setSelectedStatus(value);
        }
        //Set new displayed requests
        setDisplayedRequests(finalFiltered);
    };

    return (
        <main className="cctv-admin-home">
            <h1 className="cctv-header">CCTV - Admin Area</h1>
            <section className="cctv-lookup">
                <div className="cctv-btns">
                    <Tooltip title="Help">
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => setOpenHelp(true)}
                            disabled={disable}
                        >
                            <HelpIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Refresh">
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleRefresh}
                            disabled={disable}
                        >
                            <RefreshIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="CCTV Form">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenForm(true)}
                            disabled={disable}
                        >
                            <AddIcon />
                        </Button>
                    </Tooltip>
                </div>

                {/* FILTERS SECTION */}
                <div className="cctv-filters">
                    <select
                        className="type-filter"
                        onChange={(e) => {
                            handleFilter(parseInt(e.target.value), "type");
                        }}
                        value={selectedType}
                    >
                        <option value={0}>Filter By Type</option>
                        {types && types.length > 0
                            ? types.map((type: any, i: number) => {
                                  return (
                                      <option key={i} value={type.typeId}>
                                          {type.name}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                    <select
                        className="site-filter"
                        onChange={(e) => {
                            handleFilter(e.target.value, "site");
                        }}
                        value={selectedSite}
                    >
                        <option value={""}>Filter By Site</option>
                        {sites && sites.length > 0
                            ? sites.map((site: any, i: number) => {
                                  return (
                                      <option key={i} value={site.pinNumber}>
                                          {site.name}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                    <select
                        className="status-filter"
                        onChange={(e) => {
                            handleFilter(parseInt(e.target.value), "status");
                        }}
                        value={selectedStatus}
                    >
                        <option value={0}>Filter By Status</option>
                        {statuses && statuses.length > 0
                            ? statuses.map((status: any, i: number) => {
                                  return (
                                      <option key={i} value={status.statusId}>
                                          {status.name}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                </div>
                {/* SEARCH SECTION */}
                <div className="cctv-search">
                    <div className="single-search">
                        <input
                            type="text"
                            placeholder="Search Reg"
                            onChange={(e) => setRegSearch(e.target.value)}
                            value={regSearch}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && regSearch.length > 0) {
                                    handleRegCheck();
                                } else if (e.key === "Enter") {
                                    setDisplayedRequests(fixedRequests);
                                }
                            }}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            className="search-btn"
                            onClick={handleRegCheck}
                            disabled={disable || regSearch === ""}
                        >
                            <SearchIcon />
                        </Button>
                    </div>
                    <div className="single-search">
                        <input
                            type="text"
                            placeholder="Search Crime Ref"
                            onChange={(e) => setCrimeRefSearch(e.target.value)}
                            value={crimeRefSearch}
                            onKeyDown={(e) => {
                                if (
                                    e.key === "Enter" &&
                                    crimeRefSearch.length > 0
                                ) {
                                    handleCrimeRefCheck();
                                } else if (e.key === "Enter") {
                                    setDisplayedRequests(fixedRequests);
                                }
                            }}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            className="search-btn"
                            onClick={handleCrimeRefCheck}
                            disabled={disable || crimeRefSearch === ""}
                        >
                            <SearchIcon />
                        </Button>
                    </div>
                    <div className="single-search">
                        <input
                            type="date"
                            placeholder="Search Date"
                            className="date-input"
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setDisplayedRequests(fixedRequests);
                                    setDateRangeStart("");
                                } else {
                                    handleDateSearch(e.target.value, "start");
                                }
                            }}
                            value={dateRangeStart}
                            max={dateRangeEnd}
                        />
                        <span>-</span>
                        <input
                            type="date"
                            placeholder="Search Date"
                            className="date-input"
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setDisplayedRequests(fixedRequests);
                                    setDateRangeEnd("");
                                } else {
                                    handleDateSearch(e.target.value, "end");
                                }
                            }}
                            value={dateRangeEnd}
                            min={dateRangeStart}
                        />
                    </div>
                </div>
            </section>
            {/* MAIN TABLE SECTION */}

            {!loader ? (
                displayedRequests.length > 0 ? (
                    <section className="cctv-admin-table-container">
                        <table className="cctv-admin-table">
                            <thead>
                                <tr>
                                    <th>Site</th>
                                    <th>Submitted By</th>
                                    <th>Date/Time</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Reason/Reg(£)</th>
                                    <th>Crime Ref</th>
                                    <th>Info</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedRequests.length > 0
                                    ? displayedRequests.map(
                                          (r: any, i: number) => {
                                              if (i <= 100) {
                                                  return (
                                                      <CCTVAdminRow
                                                          key={i}
                                                          request={r}
                                                          statuses={statuses}
                                                          user={user}
                                                          reloadRequestData={
                                                              fetchRequests
                                                          }
                                                      />
                                                  );
                                              }
                                          }
                                      )
                                    : null}
                            </tbody>
                        </table>
                    </section>
                ) : (
                    <h2>No Requests</h2>
                )
            ) : (
                <Loader />
            )}
            {/* MODALS */}
            <Modal open={openForm}>
                <CCTVAdminForm
                    setOpenForm={setOpenForm}
                    user={user}
                    reloadRequestData={fetchRequests}
                    sites={sites}
                />
            </Modal>
            <Modal open={openHelp}>
                <CCTVAdminHelp setOpenHelp={setOpenHelp} />
            </Modal>
        </main>
    );
}
