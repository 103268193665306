import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

//STYLING
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function VehicleDetails({
    registration,
    setShowVehicleDetailModal,
}) {
    const url = process.env.REACT_APP_ANPR_URL;
    const [vehicle, setVehicle] = useState({});
    const [anpr, setAnpr] = useState([]);

    useEffect(() => {
        let data = {
            plate: registration,
        };
        axios
            .post(`${url}/cctv-system/dvla`, data)
            .then((res) => {
                setVehicle(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axios
            .get(
                `https://nitrocam-back.nitrosolutions.co.uk/car/history/find?plate=${registration}`
            )
            .then((res) => {})
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <Box sx={style} className="all_modal">
            <Typography id="vehicle_details_header" variant="h6" component="h2">
                Vehicle Details
            </Typography>
            <table className="modal__tables">
                <tbody>
                    <tr>
                        <th>Registration</th>
                        <td>{vehicle.registrationNumber}</td>
                    </tr>
                    <tr>
                        <th>Fuel Type</th>
                        <td>{vehicle.fuelType}</td>
                    </tr>
                    <tr>
                        <th>Make</th>
                        <td>{vehicle.make}</td>
                    </tr>
                    <tr>
                        <th>Colour</th>
                        <td>{vehicle.colour}</td>
                    </tr>
                    <tr>
                        <th>Registration Year</th>
                        <td>{vehicle.yearOfManufacture}</td>
                    </tr>
                    <tr>
                        <th>MOT</th>
                        <td>{vehicle.motStatus}</td>
                    </tr>
                    <tr>
                        <th>Taxed</th>
                        <td>{vehicle.taxStatus}</td>
                    </tr>
                </tbody>
            </table>
            {anpr.length > 0 ? (
                <>
                    <hr />
                    <p>
                        <u>
                            <b>Detected By ANPR</b>
                        </u>
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Site</th>
                                <th>Pump</th>
                                <th>Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {anpr.map((d, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{d.site}</td>
                                        <td>{d.line}</td>
                                        <td>
                                            {new Date(d.date).toLocaleString()}
                                        </td>
                                        <td>{d.status}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>
            ) : (
                <p>NO ANPR DATA</p>
            )}
            <Typography sx={{ mt: 2 }}>
                <Button
                    color="error"
                    variant="contained"
                    onClick={() => setShowVehicleDetailModal(false)}
                    sx={{ width: "100%" }}
                >
                    close
                </Button>
            </Typography>
        </Box>
    );
}
