import { useEffect, useState } from "react";
import CCTVIndividual from "./CCTVIndividual";
import axios from "axios";
import Loader from "../../animations/Loader";

export default function CCTVRequests({ statusList }) {
    const url = process.env.REACT_APP_ANPR_URL;

    const [displayedRequests, setDisplayedRequests] = useState([]);
    const [fixedRequests, setFixedRequests] = useState([]);
    const [reload, setReload] = useState(false);
    const [loader, setLoader] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(0);

    useEffect(() => {
        setSelectedStatus(0);
        setLoader(true);
        fetchCCTVRequests();
    }, [reload]);

    const fetchCCTVRequests = async () => {
        await axios
            .get(`${url}/cctv-system/submissions/details/allActive`)
            .then((res) => {
                // sort CCTV requests by submission date
                let _requests = res.data.cctv;
                _requests = _requests.sort(
                    (a, b) =>
                        new Date(b.submissionDate) - new Date(a.submissionDate)
                );

                setFixedRequests(_requests);
                setDisplayedRequests(_requests);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    };

    const rel = () => {
        setReload(!reload);
    };

    //Will filter displayed requests status filters
    const handleFilter = (value, field) => {
        let filterData = {
            statusId: field === "status" ? value : selectedStatus,
        };

        //Remove any key/value pairs from the filtered data where the value is 0 (default)
        const removeZeros = (item) =>
            Object.keys(item)
                .filter((key) => item[key] !== 0 && item[key] !== "")
                .reduce((newObj, key) => {
                    newObj[key] = item[key];
                    return newObj;
                }, {});
        const _result = removeZeros(filterData);

        //Create a new filter array of the key value pairs that have to be filtered
        let filteredArray = [];
        for (const [key, value] of Object.entries(_result)) {
            filteredArray.push({ key: key, value: value });
        }

        //Loop through filter array and set new array
        let finalFiltered = fixedRequests;
        for (let index = 0; index < filteredArray.length; index++) {
            finalFiltered = finalFiltered.filter(
                (order) =>
                    order[filteredArray[index].key] ==
                    filteredArray[index].value
            );
        }

        //Set Current filter selects
        if (field === "status") {
            setSelectedStatus(value);
        }
        //Set new displayed requests
        setDisplayedRequests(finalFiltered);
    };

    return (
        <main className="cctv__requests">
            <h1 className="cctv-header">CCTV - Dev Area</h1>

            {!loader ? (
                <>
                    <section className="filter-section">
                        <select
                            className="status-filter"
                            onChange={(e) => {
                                handleFilter(
                                    parseInt(e.target.value),
                                    "status"
                                );
                            }}
                            value={selectedStatus}
                        >
                            <option value={0}>Filter By Status</option>
                            {statusList && statusList.length > 0
                                ? statusList.map((status, i) => {
                                      return (
                                          <option
                                              key={i}
                                              value={status.statusId}
                                          >
                                              {status.name}
                                          </option>
                                      );
                                  })
                                : null}
                        </select>
                    </section>
                    {displayedRequests?.length > 0 ? (
                        <section className="cctv-dev-table-container">
                            <table className="cctv__table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th className="filter_heading">
                                            Incident Date
                                        </th>
                                        <th className="filter_heading">Site</th>
                                        <th>Reason</th>
                                        <th className="filter_heading">
                                            Status
                                        </th>
                                        <th>Storage</th>
                                        <th>Notes</th>
                                        <th>QR</th>
                                        <th>Submission Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayedRequests.length > 0 ? (
                                        displayedRequests.map((request, i) => {
                                            return (
                                                <CCTVIndividual
                                                    request={request}
                                                    statusList={statusList}
                                                    rel={rel}
                                                />
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <th colSpan={9}>
                                                No open requests
                                            </th>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </section>
                    ) : (
                        <h2>No CCTV Requests</h2>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </main>
    );
}
